import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";

// Dashboard Cards Action
export const dashboardCardAction = createAsyncThunk("dashboardCardAction/shop", async () => {
  const response = await api.get("/shop/dashboard");
  return response.data;
}
);

// shop create action
export const shopCreateAction = createAsyncThunk("shopCreateAction/shop", async (data) => {
  const response = await api.post("/shop", data);
  return response.data;
}
);

// shop opening timing
export const shopOpeningTiming = createAsyncThunk('shopOpeningTiming/shop', async ({ id, data }) => {
  const response = await api.put(`/shop/${id}`, data);
  return response.data;
}
);

export const shopEditAction = createAsyncThunk('shopEditAction/shop', async (reqBody, thunkAPI) => {
  try {
    const { id, formData } = reqBody
    const response = await api.put(`/shop/${id}`, formData)
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

// shop list
export const getShopList = createAsyncThunk('getShopList/shop', async () => {
  const response = await api.get("/shop");
  return response.data;
}
);

// shop detail by id
export const shopDetailParticularById = createAsyncThunk('shopDetailParticularById/shop', async (id) => {
  const response = await api.get(`/shop/${id}`);
  return response.data;
}
);

export const shopDeleteAction = createAsyncThunk('shopDeleteAction/delete', async (id) => {
  const response = await api.delete(`/shop/${id}`);
  return response.data;
}
);
