import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

export default function AllowLocation() {
  return (
    <div className="login-bg">
      {/* <div className="">
        <img
          src={require("../Assets/Images/cake-l-b.png")}
          className="cake-l-b"
        />
        <img
          src={require("../Assets/Images/cake-r-t.png")}
          className="cake-r-t"
        />
        <img
          src={require("../Assets/Images/cake-r-b.png")}
          className="cake-r-b"
        />
      </div> */}
      <Container fluid>
        <Row className="box-center">
        <Col lg={7} className="p-0">
            <div className="sign-left-img">
              <img src={require("../Assets/Images/sign-banner.png")} />
            </div>
          </Col>
          <Col lg={5} className="">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap height-none">
                <h2 className="text-center">Allow Location</h2>
                <p className="text-center mt-3 mb-3">
                  Your Location details will help us get in touch with you
                </p>
                <div className="text-center mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="115"
                    height="130"
                    viewBox="0 0 115 130"
                    fill="none"
                  >
                    <path
                      d="M57.5001 0C34.509 0 15.3334 18.4006 15.3334 41.8945C15.3334 50.8325 18.0367 58.8027 23.2247 66.2711L54.2732 114.407C55.7794 116.747 59.2238 116.742 60.727 114.407L91.9101 66.1073C96.9865 58.9773 99.6667 50.6053 99.6667 41.8945C99.6667 18.7939 80.7508 0 57.5001 0ZM57.5001 60.9375C46.9321 60.9375 38.3334 52.3943 38.3334 41.8945C38.3334 31.3947 46.9321 22.8516 57.5001 22.8516C68.0681 22.8516 76.6667 31.3947 76.6667 41.8945C76.6667 52.3943 68.0681 60.9375 57.5001 60.9375Z"
                      fill="#8391A1"
                    />
                    <path
                      d="M87.4675 87.5202L68.1651 117.503C63.1687 125.243 51.8034 125.217 46.8311 117.511L27.4973 87.5281C10.4865 91.4357 0 98.5941 0 107.148C0 121.992 29.626 130 57.5 130C85.374 130 115 121.992 115 107.148C115 98.588 104.499 91.4258 87.4675 87.5202Z"
                      fill="#8391A1"
                    />
                  </svg>
                </div>
                <Form>
                  <Link
                    to="/FillDetail"
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Allow
                  </Link>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
