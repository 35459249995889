import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function LogoutModal(props) {
    const handleYes = () => {
        props.onConfirm(); 
        props.onHide(); 
    };

    const handleNo = () => {
        props.onHide(); 
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="">
                    <div className="container">
                       <div className="logout-notif">
                       <form>
                            <h2>Are you sure you want to logout?</h2>
                            <div className="d-flex gap-2 justify-content-center">
                           
                                    <Button className="btn-outline " onClick={handleYes}>
                                        Yes
                                    </Button>
                                    <Button className="btn-fill" onClick={handleNo}>
                                        No
                                    </Button>
                              
                            </div>
                        </form>
                       </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
