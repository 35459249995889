import React from 'react'
import { Form, InputGroup, Modal } from 'react-bootstrap'
import { editProductDetail, particularProductDetailById } from '../Redux/Actions/productAction'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

const GoodToGoModal = ({ goodToGo, IsShowGoModal, setIsShowModalOpen, setGoodToGoPrice, goodToGoPrice, setGoodToGo, productId }) => {

    const dispatch = useDispatch();
    console.log("goodToGo", goodToGo)
    const handleclose = () => {
        setGoodToGo(!goodToGo)
        setIsShowModalOpen(false)
    }

    const handleSave = async () => {
        if (goodToGo && !goodToGoPrice) {
            toast.error("Please provide a price for 'Good to go'.");
            return;
        }
        const data = {
            good_to_go: goodToGo === true ? 1 : 0,
            good_to_go_price: goodToGo === true ? goodToGoPrice : 0,
        };
        let apiRes = await dispatch(editProductDetail({ id: productId, data }))
        if (apiRes.payload?.status === 200) {
            await dispatch(particularProductDetailById(productId));
            toast.success(apiRes.payload?.message);
        } else {
            toast.error(apiRes.payload?.message);
        }
        setIsShowModalOpen(false);
    };


    return (
        <>
            <Modal show={IsShowGoModal} >
                <Modal.Header closeButton onClick={() => handleclose()} className="border-none"></Modal.Header>
                <Modal.Body>
                    <div className="commmn-modal">
                        <h2>Add Price for Good to Go</h2>
                        <div className="form-set ">
                            <InputGroup className="mb-4 mt-2">
                                <Form.Control
                                    name="good_to_go_price"
                                    aria-label="Price for good to go"
                                    aria-describedby="basic-addon1"
                                    placeholder="Add Price for good to go"
                                    value={goodToGoPrice}
                                    onChange={(e) => setGoodToGoPrice(e.target.value)}

                                // disabled={!goodToGo}
                                />
                            </InputGroup>

                        </div>
                        <div className="d-flex justify-content-center">
                            <button type='button' className="btn-fill" onClick={handleSave}>Apply</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default GoodToGoModal