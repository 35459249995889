import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { Decryptedid } from "../utils/Becryprt";
import { Formik, Form as FormikForm, FieldArray, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { shopCreateAction, shopDetailParticularById, shopOpeningTiming } from "../Redux/Actions/shopAction";
import toast from "react-hot-toast";
import * as Yup from 'yup';
import { convertTimeToUTC, convertUTCToLocal } from "../utils/commonFunction";
import { ProgressBar } from "react-bootstrap";
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageShopOpeningHoursAdd({
    formData,
    setFormData,
    nextStep,
    prevStep }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allDaysValues = [
        { day: "Monday", from: "", to: "" },
        { day: "Tuesday", from: "", to: "" },
        { day: "Wednesday", from: "", to: "" },
        { day: "Thursday", from: "", to: "" },
        { day: "Friday", from: "", to: "" },
        { day: "Saturday", from: "", to: "" },
        { day: "Sunday", from: "", to: "" }
    ];

    const initialValues = {
        opening_hours: allDaysValues.map((item) => {
            const from = item?.from ? convertUTCToLocal(item.from) : ""; // Handle empty value
            const to = item?.to ? convertUTCToLocal(item.to) : ""; // Handle empty value

            console.log(`Day: ${item.day}, From: ${from}, To: ${to}`); // Debugging log
            return {
                ...item,
                from,
                to,
            };
        })
    };


    console.log("formDataformData", formData)

    const validationSchema = Yup.object().shape({
        opening_hours: Yup.array().of(
            Yup.object().shape({
                from: Yup.string()
                    .nullable()
                    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format')
                    .test('from-to', 'Both "from" and "to" must be provided', function (value) {
                        const { to } = this.parent;
                        // Validate that if either 'from' or 'to' is provided, the other must also be provided
                        return (!value && !to) || (value && to);
                    }),
                to: Yup.string()
                    .nullable()
                    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format')
                    .test('from-to', 'Both "from" and "to" must be provided', function (value) {
                        const { from } = this.parent;
                        // Validate that if either 'from' or 'to' is provided, the other must also be provided
                        return (!value && !from) || (value && from);
                    }),
            })
        )
    });



    return (
        <Layout>
            <div className="right-top">
                <div className="heading-top">
                    <div className="common-head">
                        <h2>{Icons.ManageShopIcon}
                            Add Shop </h2>
                    </div>
                    <div className="common-icon-side">
                        <NotificationComp />
                    </div>
                </div>
            </div>

            <Container fluid className="px-0">
                <div className="dashboard-items">
                    <Row className="mt-5">
                        <Col lg={12} className="m-auto">
                            <div className="membership-progress">
                                <div className="step-membership">
                                    <ProgressBar now={100} />
                                    <p>
                                        <span>Step 4:</span> Add Shop Opening Hours
                                    </p>
                                </div>
                            </div>

                            <div className="login-cmn-box ad-inner-width select-mainplan-box">
                                <h2 className="text-center"> Add Shop Opening Hours</h2>
                                <Formik
                                    initialValues={initialValues}
                                    // validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        const hasFilledTime = values?.opening_hours?.some(day => day?.from && day?.to);
                                        if (!hasFilledTime) {
                                            return toast.error("At least one day must have both start and end times filled")
                                        }
                                        console.log("values", values)
                                        console.log("formData", formData)
                                        setSubmitting(false);
                                        //  resetForm();
                                        const hours = values?.opening_hours
                                            .filter(({ from, to }) => from && to)
                                            .map(({ day, from, to, _id }) => {
                                                const result = {
                                                    day,
                                                    from: from ? convertTimeToUTC(from) : "",
                                                    to: to ? convertTimeToUTC(to) : "",
                                                };
                                                if (_id) {
                                                    result._id = _id;
                                                }
                                                return result;
                                            });
                                        if (hours?.length === 0) {
                                            return toast.error("Please add Opening Hours")
                                        }
                                        const formDataToSubmit = new FormData();
                                        formDataToSubmit.append("govt_id", formData?.file);
                                        formDataToSubmit.append("opening_hours", JSON.stringify(hours))

                                        Object.keys(formData).forEach((key) => {
                                            if (
                                                key !== "file" &&
                                                key !== "formDataImage" &&
                                                key !== "formDataToSubmit" &&
                                                formData[key]
                                            ) {
                                                formDataToSubmit.append(key, formData[key]);
                                            }
                                        });

                                        setFormData({
                                            ...formData,
                                            ...values,
                                        });
                                        nextStep();
                                        dispatch(shopCreateAction(formDataToSubmit)).then(
                                            (res) => {
                                                console.log(res?.payload, "apiRes");
                                                let id = res?.payload?.data?._id;

                                                if (res?.payload?.status === 200 && id) {
                                                    toast.success(res?.payload?.message);
                                                    navigate(
                                                        `/ManageShop`
                                                    );
                                                } else {
                                                    toast.error(res?.payload?.message);
                                                }
                                            }
                                        );

                                    }}
                                >
                                    {({ values, handleChange, errors, touched }) => (
                                        <FormikForm>
                                            <Row className="align-items-center mb-3">
                                                <Col lg={10} className="mx-auto opening-hours-box">
                                                    <FieldArray name="opening_hours">
                                                        {({ insert, remove, push }) => (
                                                            <div>
                                                                {values?.opening_hours?.map((day, index) => (
                                                                    <Row key={index} className="align-items-center mb-3">
                                                                        <Col lg={2}>
                                                                            <p>{day?.day}</p>
                                                                        </Col>
                                                                        <Col lg={5}>
                                                                            <Form.Control
                                                                                as="input"
                                                                                name={`opening_hours.${index}.from`}
                                                                                type="time"
                                                                                // value={values?.opening_hours[index]?.from}
                                                                                value={day?.from || ""}
                                                                                onChange={handleChange}
                                                                                placeholder="From"
                                                                            />
                                                                        </Col>
                                                                        <Col lg={5}>
                                                                            <Form.Control
                                                                                as="input"
                                                                                name={`opening_hours.${index}.to`}
                                                                                type="time"
                                                                                // value={values?.opening_hours[index]?.to}
                                                                                value={day?.to || ""}
                                                                                onChange={handleChange}
                                                                                placeholder="To"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </Col>
                                            </Row>

                                            {errors?.opening_hours && touched?.opening_hours && (
                                                <div className="text-danger text-center mb-3">
                                                    {typeof errors?.opening_hours === 'string' ? errors?.opening_hours : JSON.stringify(errors?.opening_hours)}
                                                </div>
                                            )}

                                            <button type="submit" className="submit filldetails-btn">
                                                Submit
                                            </button>
                                        </FormikForm>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    );
}
