import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api";
import { constructQueryString } from "../../utils/commonFunction";


export const getOrderList = createAsyncThunk('getOrderList/order', async (reqBody) => {
    const queryString = constructQueryString(reqBody);
    const response = await api.get(`/shop/orders?${queryString}`);
    return response;
}
);


export const orderDetailParticularById = createAsyncThunk('orderDetailParticularById/order', async (reqBody) => {
    const { id } = reqBody;
    const response = await api.get(`/shop/order/${id}`);
    return response.data;
}
);

export const orderStatusAction = createAsyncThunk('orderStatusAction/order', async (reqBody) => {
    const { id, ...payload } = reqBody;
    const response = await api.put(`/shop/order/${id}`, payload);
    return response.data;
}
);

export const orderPickedUpAction = createAsyncThunk('orderPickedUpAction/order', async (reqBody) => {
    const { id, ...payload } = reqBody;
    const response = await api.put(`/shop/takeaway-status/${id}`, payload);
    return response.data;
}
);

export const getInvoiceList = createAsyncThunk('getInvoiceList/order', async (reqBody) => {
    const queryString = constructQueryString(reqBody);
    const response = await api.get(`/shop/invoices?${queryString}`);
    return response;
}
);

export const getWalletList = createAsyncThunk('getWalletList/order', async (reqBody) => {
    const queryString = constructQueryString(reqBody);
    const response = await api.get(`/shop/wallets?${queryString}`);
    return response;
}
);


export const addBankDetailsAction = createAsyncThunk('add-bank/details', async (reqBody) => {
    const response = await api.put(`/user/bank-detail`, reqBody);
    return response.data;
}
);

export const addBankStripeAction = createAsyncThunk('bank/action', async (reqBody) => {
    const response = await api.post(`/user/bank-account`, reqBody);
    return response.data;
}
);
export const getBankDetails = createAsyncThunk('get-bank/details', async (reqBody) => {
    const response = await api.get(`/user/profile`);
    return response;
}
);

export const withdrawAmtAction = createAsyncThunk('withdraw/amt', async (reqBody) => {
    const queryString = constructQueryString(reqBody);
    const response = await api.get(`/shop/withdraw-amount?${queryString}`);
    return response.data;
}
);

export const getDownloadInvoiceUrl = createAsyncThunk('download/invoice-url', async (reqBody) => {
    const queryString = constructQueryString(reqBody);
    const response = await api.get(`/shop/invoice?${queryString}`);
    return response;
}
);

export const getCustomerReviewsAction = createAsyncThunk('list/reviews', async (reqBody) => {
    const response = await api.get(`/shop/products/${reqBody?.id}/reviews`);
    return response;
}
);

export const getReviewsOnDashboardAction = createAsyncThunk('dashboard/review-list', async (reqBody) => {
    const response = await api.get(`/shop/all-reviews`);
    return response;
}
);
