import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const COLORS = ['#a72a2f', '#e0e0e0']; // Define colors for growth and remaining percentage

const GrowthChart = () => {
    const [data, setData] = useState([]);
    const growthPercentage = 40; // Example: 40% company growth

    useEffect(() => {
        const fetchData = () => {
            const formattedData = [
                { name: 'Growth', value: growthPercentage },
                { name: 'Remaining', value: 100 - growthPercentage },
            ];
            setData(formattedData);
        };

        fetchData();
    }, [growthPercentage]);

    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Tooltip />
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        outerRadius={60}
                        startAngle={90}
                        endAngle={-270}
                        // Add custom label in the center
                        labelLine={false}
                        label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
                            const RADIAN = Math.PI / 180;
                            const radius = 25 + innerRadius + (outerRadius - innerRadius) * 0.5;
                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                            const y = cy + radius * Math.sin(-midAngle * RADIAN);

                            // Only display the percentage label in the center
                            if (index === 0) {
                                return (
                                    <text
                                        x={cx}
                                        y={cy}
                                        fill="black"
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fontSize={20}
                                        fontWeight="bold"
                                    >
                                        {`${growthPercentage}%`}
                                    </text>
                                );
                            }
                            return null;
                        }}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default GrowthChart;
