import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

export default function AddShop() {
  return (
    <div className="login-bg">
      <div className="">
        <img
          src={require("../Assets/Images/cake-l-b.png")}
          className="cake-l-b"
        />
        <img
          src={require("../Assets/Images/cake-r-t.png")}
          className="cake-r-t"
        />
        <img
          src={require("../Assets/Images/cake-r-b.png")}
          className="cake-r-b"
        />
      </div>
      <Container>
        <Row className="box-center">
          <Col lg={5} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap height-none">
                <div className="text-center mb-5">
                  <img src={require("../Assets/Images/shop-icon.png")} />
                </div>
                <h2 className="text-center">Add Shop</h2>
                <p className="text-center mt-3">
                  Just a few steps to complete and then you can start earning
                  with us
                </p>
                <Form>
                  <Link
                    to="/Dashboard"
                    variant="primary"
                    type="submit"
                    className="submit"
                  >
                    Add Shop
                  </Link>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
