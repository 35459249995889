import React, { useEffect } from 'react';
import Slider from "react-slick";
import Icons from '../../Assets/icons';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerReviewsAction } from '../../Redux/Actions/orderAction';
import { fullName } from '../../utils/commonFunction';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating'


var settings = {
    infinite: false,
    nav: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerPadding: '40px',
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerPadding: '40px',
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerPadding: '40px',
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const CustomerReviewsComponent = ({ id }) => {
    const slider = React.useRef(null);
    const dispatch = useDispatch();
    const { reviewsList } = useSelector((state) => state?.orderAuthData)

    console.log("reviewsList", reviewsList)

    useEffect(() => {
        dispatch(getCustomerReviewsAction({ id }));
    }, [])


    return (
        <>
            <div className="dashboard-items">
                <Row>
                    <Col lg={12}>
                        <div className="cake-review-box">
                            <h2>Customer Review</h2>
                            {
                                !reviewsList?.data?.length &&
                                <p className='text-center'>
                                    No Reviews
                                </p>
                            }
                            <Slider {...settings} ref={slider} className="slick-slide-wrapper" >
                                {
                                    Array.isArray(reviewsList?.data) && reviewsList?.data?.length > 0 &&
                                    reviewsList?.data?.map((res) => {
                                        return <>
                                            <div className="cake-review-card">
                                                <div className="cake-review-right">
                                                    <div className="user-review-pro">
                                                        <img src={res?.customer_id?.profile_pic ? `${process.env.REACT_APP_BASE_URL}public/${res?.customer_id?.profile_pic}` : require("../../Assets/Images/user.png")} style={{ height: "50px", width: "50px", borderRadius: '50%' }} />
                                                        <div className="">
                                                            <h3>{fullName(res?.customer_id?.first_name, res?.customer_id?.last_name)}</h3>
                                                            <p>{res?.createdAt && moment(res?.createdAt).fromNow()}</p>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        {res?.description}
                                                    </p>
                                                    <div className="manage-shop-star">
                                                        <Rating
                                                            disableFillHover={true}
                                                            readonly={true}
                                                            initialValue={res?.rating ?? 0}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="cake-review-left">
                                                    <img src={res?.product_id?.images?.length > 0 ? `${process.env.REACT_APP_BASE_URL}public/${res?.product_id?.images[0]}` : require("../../Assets/Images/user.png")} style={{ height: "180px", width: "200px" }} />
                                                </div>
                                            </div>
                                        </>
                                    })
                                }

                            </Slider>
                            {/* <div className="slick-buttons">
                  <button onClick={() => slider?.current?.slickPrev()}>
                  </button>
                  <button onClick={() => slider?.current?.slickNext()}>
                  </button>
                </div> */}

                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CustomerReviewsComponent