import { useState, useCallback, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import toast from 'react-hot-toast';

const firebaseConfig = {
    apiKey: "AIzaSyCpqa5f5gl95zw_iBmZWPPuCe0ENNOm_DQ",
    authDomain: "milcakepartner.firebaseapp.com",
    projectId: "milcakepartner",
    storageBucket: "milcakepartner.appspot.com",
    messagingSenderId: "626397822942",
    appId: "1:626397822942:web:bb1e2f1de742245203e74f"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const useFirebaseToken = () => {
    const [token, setToken] = useState(null);

    const getFireBaseToken = useCallback(async () => {
        try {
            const response = await Notification.requestPermission();
            if (response === "granted") {
                console.log('Notification permission granted.');
                try {
                    const fetchedToken = await getToken(messaging, { vapidKey: "BJHvnG8aEwQrjB9_5Ea91IH2M5etknu7JQzKrma3_qOoeMlkED7reZu4S1VF-5pSEwCi0-88uGC_hdBYBaRGFUQ" });
                    if (fetchedToken) {
                        setToken(fetchedToken);
                        return fetchedToken;
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                } catch (error) {
                    console.error('An error occurred while retrieving token. ', error);
                }
            } else {
                console.log('Unable to get permission to notify.');
            }
        } catch (error) {
            console.error("Error requesting notification permission.", error);
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            toast.success(`${payload.notification.title}: ${payload.notification.body}`);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return { token, getFireBaseToken };
};

export default useFirebaseToken;
