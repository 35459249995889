import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Encryptedid } from '../utils/Becryprt'

const EditShopModal = ({ isModalShopOpen, setIsModalShopOpen, id }) => {
    return (
        <>
            <Modal show={isModalShopOpen} onHide={() => setIsModalShopOpen(false)}>
                <Modal.Header closeButton className="border-none"></Modal.Header>
                <Modal.Body>
                    <div className="commmn-modal">
                        <h2>Edit Shop</h2>
                        <div className="edit-shop-pop">
                            <ul>
                                <Link to={`/EditSelectPlan/${Encryptedid(id)}`} className='no-underline'>
                                    <li>
                                        Edit Plan

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="14"
                                            viewBox="0 0 9 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.72769 7L0 1.55531L1.63615 0L9 7L1.63615 14L0 12.4447L5.72769 7Z"
                                                fill="#969AA4"
                                            />
                                        </svg>
                                    </li>
                                </Link>

                                <Link to={`/EditShopDetails/${Encryptedid(id)}`} className='no-underline'>
                                    <li>
                                        Edit Shop Details
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="14"
                                            viewBox="0 0 9 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.72769 7L0 1.55531L1.63615 0L9 7L1.63615 14L0 12.4447L5.72769 7Z"
                                                fill="#969AA4"
                                            />
                                        </svg>
                                    </li>
                                </Link>
                                <Link to={`/EditShopImages/${Encryptedid(id)}`} className='no-underline'>
                                    <li>
                                        Edit Images
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="14"
                                            viewBox="0 0 9 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.72769 7L0 1.55531L1.63615 0L9 7L1.63615 14L0 12.4447L5.72769 7Z"
                                                fill="#969AA4"
                                            />
                                        </svg>
                                    </li>
                                </Link>
                                <Link to={`/EditOpeningHours/${Encryptedid(id)}`} className='no-underline'>
                                    <li>
                                        Edit Shop Opening Hours
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="14"
                                            viewBox="0 0 9 14"
                                            fill="none"
                                        >
                                            <path
                                                d="M5.72769 7L0 1.55531L1.63615 0L9 7L1.63615 14L0 12.4447L5.72769 7Z"
                                                fill="#969AA4"
                                            />
                                        </svg>
                                    </li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EditShopModal