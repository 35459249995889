import { createSlice } from "@reduxjs/toolkit";
import { getNotificationListAction, getProfileDetails, getSupportDetailsAction } from "../Actions/auth";


const initialState = {
  loading: false,
  userInfo: [],
  messageList: {},
  error: false,
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileDetails.fulfilled, (state, { payload }) => {
        state.userInfo = payload.data
        state.loading = false;
      })
      .addCase(getProfileDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getNotificationListAction.fulfilled, (state, { payload }) => {
        state.notifyList = payload.data
        state.loading = false;
      })
      .addCase(getSupportDetailsAction.fulfilled, (state, { payload }) => {
        state.messageList = payload
        state.loading = false;
      })
  },
});


export default userAuthSlice.reducer;
