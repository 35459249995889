import React from 'react';
import { Modal, Button } from "react-bootstrap";
import toast from 'react-hot-toast';
import { getOrderList, orderDetailParticularById, orderStatusAction } from '../../../Redux/Actions/orderAction';
import { useDispatch } from 'react-redux';

const OrderAcceptRejectModal = (props) => {
    console.log("props", props);
    const dispatch = useDispatch();

    const handleOrderStatus = async () => {
        const payload = {
            status: props?.data?.type === "accept" ? '1' : '2',
            id: props?.data?.id
        };
        await dispatch(orderStatusAction(payload)).then((res) => {
            if (res?.payload?.status === 200) {
                if (props?.page === "List") {
                    dispatch(getOrderList({ status: props?.orderStatus }));
                } else {
                    dispatch(orderDetailParticularById({ id: props?.data?.id }))
                }
                toast.success(res?.payload?.message);
            } else {
                toast.error(res?.payload?.message);
            }
        });
        props?.onHide();
    };

    const handleNo = () => {
        props.onHide();
    }

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="">
                        <div className="container">
                            <div className="logout-notif">
                                <form>
                                    <h2>Are you sure you want to {props?.data?.type === "accept" ? `Accept` : `Decline`} Order ?</h2>
                                    <div className="d-flex gap-2 justify-content-center">

                                        <Button className="btn-outline " onClick={handleOrderStatus}>
                                            Yes
                                        </Button>
                                        <Button className="btn-fill" onClick={handleNo}>
                                            No
                                        </Button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderAcceptRejectModal