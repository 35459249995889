import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetPassword } from "../Redux/Actions/auth";
import toast from "react-hot-toast";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  console.log(id);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newPassword && !confirmPassword) {
      setPasswordError("Both fields are required*");
    } else if (!newPassword) {
      setPasswordError("New Password is required*");
    } else if (!confirmPassword) {
      setPasswordError("Confirm Password is required*");
    } else if (!validatePassword(newPassword)) {
      setPasswordError("Password must be at least 6 characters");
    } else if (newPassword !== confirmPassword) {
      setPasswordError("Password do not match");
    } else {
      setPasswordError("");

      dispatch(
        resetPassword({
          _id: id,
          password: newPassword,
        })
      ).then((res) => {
        console.log(res, "4455555555555");
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate("/PasswordChanged");
        }
      });
    }
  };

  return (
    <div className="login-bg">
      {/* <div className="">
        <img
          src={require("../Assets/Images/cake-l-b.png")}
          className="cake-l-b"
        />
        <img
          src={require("../Assets/Images/cake-r-t.png")}
          className="cake-r-t"
        />
        <img
          src={require("../Assets/Images/cake-r-b.png")}
          className="cake-r-b"
        />
      </div> */}
      <Container fluid>
        {id && (
          <Row className="box-center">
            <Col lg={7} className="p-0">
              <div className="sign-left-img">
                <img src={require("../Assets/Images/sign-banner.png")} />
              </div>
            </Col>
            <Col lg={5}>
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap height-none">
                  <h2 className="text-center">Reset Password</h2>
                  <p className="text-center mt-3">
                    Your new password must be unique from those previously used.
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <div className="form-set height-none">
                      <Form.Label> New Password</Form.Label>

                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="17"
                            viewBox="0 0 14 17"
                            fill="none"
                          >
                            <path
                              d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03057C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                              fill="#8391A1"
                            />
                          </svg>
                        </InputGroup.Text>
                        <Form.Control
                          className="border-right-o"
                          type={showNewPassword ? "text" : "password"}
                          placeholder="New Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="pass-show"
                          onClick={() => setShowNewPassword((prev) => !prev)}
                        >
                          <img
                            src={require("../Assets/Images/eye.svg").default}
                            alt="eye icon"
                          />
                        </button>
                      </InputGroup>
                      <Form.Label> Confirm Password</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="17"
                            viewBox="0 0 14 17"
                            fill="none"
                          >
                            <path
                              d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03057C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                              fill="#8391A1"
                            />
                          </svg>
                        </InputGroup.Text>
                        <Form.Control
                          className="border-right-o"
                          placeholder="Confirm Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          className="pass-show"
                          onClick={() =>
                            setShowConfirmPassword((prev) => !prev)
                          }
                        >
                          <img
                            src={require("../Assets/Images/eye.svg").default}
                            alt="eye icon"
                          />
                        </button>
                      </InputGroup>
                    </div>
                    {passwordError && (
                      <p className="text-danger">{passwordError}</p>
                    )}
                    <button
                      // to="/PasswordChanged"
                      variant="primary"
                      type="submit"
                      className="submit"
                    >
                      Reset Password
                    </button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
