import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import {
  forgotPasswordAction,
  forgotPasswordOtpVerifyAction,
  userOtpVerification,
  userResendOtpVerification,
} from "../Redux/Actions/auth";
import toast from "react-hot-toast";
import { Decryptedid, Encryptedid } from "../utils/Becryprt";

export default function LoginOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { email } = useParams();
  const userEmail = email && Decryptedid(atob(email));

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleVerify = () => {
    const emailData = localStorage.getItem("userEmail");
    const forgotPasswdUser = localStorage.getItem("forgotPassword_id");
    
    const verifyObj = {
      role: 3,
      email: userEmail,
      otp: otp,
    };

    if (otp.length === 4) {
      if (timer === 0) {
        setError("Your OTP has been expired.Please resend OTP");
      } else {
        if (forgotPasswdUser == 0) {
          dispatch(forgotPasswordOtpVerifyAction(verifyObj)).then((response) => {
            console.log(response, "sdfsdfsdfsd", otp);
            if (response?.payload?.status === 200) {
              toast.success(response?.payload?.message);
              localStorage.removeItem("forgotPassword_id");
              navigate(`/ResetPassword/${response?.payload?.data?._id}`);
            } else {
              toast.error(response?.payload?.message);
            }
          })
        } else {
          dispatch(userOtpVerification(verifyObj)).then((response) => {
            console.log(response, "otppppp", otp);

            if (response?.payload?.status === 200) {
              const { _id, token } = response?.payload?.data;
              const encryptedId = Encryptedid(_id);
              if (_id && token) {                
                localStorage.setItem("userId", _id);
                localStorage.setItem("token", token);
                // navigate("/FillDetail");
                window.location.href = "/FillDetail";
                toast.success(response?.payload?.message);
                // navigate(`/FillDetail/${Encryptedid(_id)}`);
              }
            } else if (response?.payload?.status === 400) {
              toast.error(response?.payload?.message);
            }
          }); 
        }
      }
    } else if ( otp.length > 1 && otp.length < 4) {
      setError("Please enter a 4-digit OTP.");
    }  else if ( timer === 0 && otp.length === 0) {
      setError("Your OTP has been expired.Please resend OTP");
    } else  {
      setError("Please enter a 4-digit OTP.");
    }
  };
  

  const handleResendFunc = () => {
    setLoading(true);
    const emailData = localStorage.getItem("userEmail");
    const forgotPasswdUser = localStorage.getItem("forgotPassword_id");

    const obj = {
      type: 2,
      role: 3,
      email: userEmail,
    };

    const obj2 = {
      "role": 3,
      "email": userEmail,
    }

    if (forgotPasswdUser == 0) {
      dispatch(forgotPasswordAction(obj2))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setOtp("")
          setError("");
          setTimer(60);
        }
      })
      .catch((error) => {
        console.error("Error resending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });


    }else {
    dispatch(userResendOtpVerification(obj))
      .then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setOtp("")
          setError("");
          setTimer(60);
        }
      })
      .catch((error) => {
        console.error("Error resending OTP:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    if (otp) {
      setError("");
    }
  }, [otp]);

  return (
    <div className="login-bg">
      {/* <div className="">
        <img
          src={require("../Assets/Images/cake-l-b.png")}
          className="cake-l-b"
          alt=""
        />
        <img
          src={require("../Assets/Images/cake-r-t.png")}
          className="cake-r-t"
          alt=""
        />
        <img
          src={require("../Assets/Images/cake-r-b.png")}
          className="cake-r-b"
          alt=""
        />
      </div> */}
      <Container fluid>
        <Row className="box-center">
        <Col lg={7} className="p-0">
            <div className="sign-left-img">
              <img src={require("../Assets/Images/sign-banner.png")} />
            </div>
          </Col>
          <Col lg={5} className="">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap height-none">
                <h2 className="text-center">OTP Verification</h2>
                <p className="text-center mt-3">
                  Please enter otp for verification
                </p>
                <Form>
                <Form.Label className="ms-5">OTP</Form.Label>
                  <div className="form-set height-none justify-content-add otp-outline-chnge">
                 
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      separator={<span style={{ width: "8px" }}></span>}
                      renderInput={(props) => <input {...props} />}
                      Style={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        width: "100px",
                        height: "54px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        // display: "flex",
                        borderBlock: "1px solid var(--Border, #E8ECF4)",
                        justifyContent: "space-between",
                      }}
                    />
                  </div>

                  <div className="mt-5">
                    {error && <p className="text-danger">{error}</p>}
                    <button
                      // to="/ResetPassword"
                      variant="primary"
                      type="button"
                      className="submit"
                      onClick={handleVerify}
                    >
                      Verify
                    </button>
                  </div>

                  <div className="mt-3 text-center">
                    {timer > 0 ? (
                      <p>Resend OTP in {formatTime(timer)} seconds</p>
                    ) : (
                      <button
                        className="btn btn-link resend-btn-txt"
                        onClick={handleResendFunc}
                        disabled={loading}
                      >
                        {loading ? "Sending..." : "Resend OTP"}
                      </button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
