import { createSlice } from "@reduxjs/toolkit";
import { arrangeproductBySubcategoryId, getCategoryAction, getFlavourAction, getSubCategoryAction, particularProductDetailById, particulatCategoryByShopId, productDetailById } from "../Actions/productAction";


const initialState = {
  loading: false,
  categoryList: [],
  flavourList: [],
  productList: [],
  productDetail: [],
  categoryData: [],
  subCategoryList: [],
  filteredProductData: [],
  error: false,
};

const productAuthSlice = createSlice({
  name: "productAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategoryAction.fulfilled, (state, { payload }) => {
        state.categoryList = payload.data;
        state.loading = false;
      })
      .addCase(getCategoryAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // subcategory action
      .addCase(getSubCategoryAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubCategoryAction.fulfilled, (state, { payload }) => {
        state.subCategoryList = payload.data;
        state.loading = false;
      })
      .addCase(getSubCategoryAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // subcategory list 

      .addCase(arrangeproductBySubcategoryId.pending, (state) => {
        state.loading = true;
      })
      .addCase(arrangeproductBySubcategoryId.fulfilled, (state, { payload }) => {
        state.filteredProductData = payload.data;
        state.loading = false;
      })
      .addCase(arrangeproductBySubcategoryId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // flavour slice
      .addCase(getFlavourAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFlavourAction.fulfilled, (state, { payload }) => {
        state.flavourList = payload.data;
        state.loading = false;
      })
      .addCase(getFlavourAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // product list slice 
      .addCase(productDetailById.pending, (state) => {
        state.loading = true;
      })
      .addCase(productDetailById.fulfilled, (state, { payload }) => {
        state.productList = payload.data;
        state.loading = false;
      })
      .addCase(productDetailById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })


       // product detail slice 
       .addCase(particularProductDetailById.pending, (state) => {
        state.loading = true;
      })
      .addCase(particularProductDetailById.fulfilled, (state, { payload }) => {
        state.productDetail = payload.data;
        state.loading = false;
      })
      .addCase(particularProductDetailById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

         // particular category by shop slice 
         .addCase(particulatCategoryByShopId.pending, (state) => {
          state.loading = true;
        })
        .addCase(particulatCategoryByShopId.fulfilled, (state, { payload }) => {
          state.categoryData = payload.data;
          state.loading = false;
        })
        .addCase(particulatCategoryByShopId.rejected, (state) => {
          state.loading = false;
          state.error = true;
        });
  },
});

export default productAuthSlice.reducer;
