import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { forgotPasswordAction } from "../Redux/Actions/auth";
import toast from "react-hot-toast";
import { Encryptedid } from "../utils/Becryprt";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = "Email is required*";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email format";
    }

    setEmailError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      let obj = {
        role: 3,
        email: email,
      };
      dispatch(forgotPasswordAction(obj)).then((res) => {
        console.log(res, "rrrrrrrrrrrrrrr");
        if (res?.payload?.status === 200) {
          localStorage.setItem("userEmail", email);
          localStorage.setItem("forgotPassword_id", 0);
          toast.success(res?.payload?.message);
          // navigate("/LoginOtp")
          navigate(`/LoginOtp/${Encryptedid(email)}`);
        } else {
          toast.error(res?.payload?.error_description);
        }
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError.email) {
      setEmailError((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  return (
    <div className="login-bg">
      {/* <div className="">
        <img
          src={require("../Assets/Images/cake-l-b.png")}
          className="cake-l-b"
        />
        <img
          src={require("../Assets/Images/cake-r-t.png")}
          className="cake-r-t"
        />
        <img
          src={require("../Assets/Images/cake-r-b.png")}
          className="cake-r-b"
        />
      </div> */}
      <Container fluid>
        <Row className="box-center">
          <Col lg={7} className="p-0">
            <div className="sign-left-img">
              <img src={require("../Assets/Images/sign-banner.png")} />
            </div>
          </Col>
          <Col lg={5} className="">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap height-none">
                <h2 className="text-center">Forgot Password?</h2>
                <p className="text-center mt-3">
                  Enter your email for reset your password
                </p>
                <Form onSubmit={handleSubmit}>
                  <div className="form-set height-none">
                    <Form.Label>Email</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                        >
                          <path
                            d="M8.99152 13.9951C7.07519 13.9951 5.15886 14.0087 3.24309 13.9906C1.70014 13.9759 0.268368 12.6922 0.0454608 11.163C0.0201942 10.9887 0.00166537 10.8115 0.00166537 10.636C-0.00114203 8.18593 -1.90675e-05 5.73642 0.00222685 3.28691C0.00278833 2.81461 0.129121 2.36996 0.328447 1.94563C0.434005 1.72161 0.494083 1.71146 0.667019 1.88469C1.19313 2.41172 1.71811 2.94045 2.24366 3.46804C3.49295 4.72299 4.72933 5.99091 5.99546 7.22836C7.66362 8.85911 10.3116 8.87604 11.9803 7.24472C13.7551 5.50902 15.4895 3.73156 17.2419 1.97215C17.279 1.93491 17.3149 1.8971 17.3525 1.86099C17.4957 1.72444 17.5597 1.73177 17.6462 1.90444C17.8539 2.31805 17.9898 2.75706 17.9915 3.22033C17.9982 5.72909 18.0072 8.23785 17.9904 10.746C17.9797 12.2995 16.6821 13.7418 15.1476 13.9517C14.9163 13.9833 14.6804 13.9957 14.4469 13.9963C12.6282 13.9996 10.8102 13.9979 8.99152 13.9979C8.99152 13.9979 8.99152 13.9968 8.99152 13.9951Z"
                            fill="#8391A1"
                          />
                          <path
                            d="M9.00051 0.00114491C10.9107 0.00114491 12.8203 -0.00280441 14.7304 0.00396687C15.3581 0.00622397 15.9309 0.212183 16.4547 0.554697C16.6316 0.670373 16.6366 0.720029 16.4867 0.871254C15.6995 1.66462 14.9112 2.4563 14.1235 3.24854C13.151 4.22642 12.1796 5.20431 11.2054 6.18107C9.93313 7.45745 8.0634 7.45689 6.7894 6.17768C5.03646 4.41828 3.28632 2.65661 1.53563 0.896082C1.35034 0.709871 1.35371 0.674324 1.5783 0.532127C2.10104 0.200334 2.66926 0.00452962 3.29025 0.0028368C5.19367 -0.00224166 7.09709 0.00114491 9.00051 0.00114491Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>

                      <Form.Control
                        className="border-right-o"
                        placeholder="Email Address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </InputGroup>
                  </div>
                  {emailError.email && (
                    <p className="text-danger">{emailError.email}</p>
                  )}

                  <button variant="primary" type="submit" className="submit">
                    Send OTP
                  </button>
                </Form>
              </div>
              <div className="mt-2 text-center" >
              <button
                className="btn btn-link resend-btn-txt"
                onClick={() => navigate("/")}
              >
                {"Back to Login"}
              </button>
              </div>
            
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
