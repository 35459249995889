import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { curveCardinal } from 'd3-shape';

const data = [
  {
    name: 'Sunday',
    orders: 0,
  },
  {
    name: 'Monday',
    orders: 200,
  },
  {
    name: 'Tuesday',
    orders: 250,
  },
  {
    name: 'Wednesday',
    orders: 300,
  },
  {
    name: 'Thursday',
    orders: 280,
  },
  {
    name: 'Friday',
    orders: 350,
  },
  {
    name: 'Saturday',
    orders: 100,
  },
];

const cardinal = curveCardinal.tension(0.2);

const WeeklyOrdersCharts = () => {
  return (
    <ResponsiveContainer width="100%" height={340}>
      <AreaChart
        data={data}

      >
        <XAxis dataKey="name" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="orders"  // Use 'orders' to represent the number of orders
          stroke="#a72a2f"
          fill="#a72a2f"
          fillOpacity={0.2}
          strokeWidth={3}
        />
        <Area
          type={cardinal}
          dataKey="orders"
          stroke="#a72a2f"
          fill="#a72a2f"
          fillOpacity={0.1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default WeeklyOrdersCharts;

