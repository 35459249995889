import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { addBankDetailsAction, getBankDetails } from '../Redux/Actions/orderAction';


const AddBankShopDetails = ({ isModalOpen, setIsModalOpen }) => {
    const dispatch = useDispatch();
    const bankData = useSelector((state) => state?.orderAuthData?.bankDetails?.data)
    const [isLoader, setIsLoader] = useState(false);
    const [initialValues, setInitialValues] = useState({
        bank_account_holder_name: "",
        bank_name: "",
        bank_account_no: "",
        bank_ifsc_code: ""
    })

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            bank_account_holder_name: Yup.string().required("Account holder name is required*"),
            bank_name: Yup.string().required("Bank name is required*"),
            bank_account_no: Yup.string().required("Account no is required*"),
            bank_ifsc_code: Yup.string().required("IFSC code is required*"),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log("values", values)
            let payload = {
                bank_account_holder_name: values?.bank_account_holder_name,
                bank_name: values?.bank_name,
                bank_account_no: values?.bank_account_no,
                bank_ifsc_code: values?.bank_ifsc_code
            }
            setIsLoader(true);
            let apiRes = await dispatch(addBankDetailsAction(payload))
            console.log("apiRes", apiRes?.payload);
            if (apiRes?.payload?.status === 200) {
                await dispatch(getBankDetails()).then((res) => {
                    let { data } = res?.payload?.data
                    let newValues = {
                        bank_account_holder_name: data?.bank_account_holder_name,
                        bank_name: data?.bank_name,
                        bank_account_no: data?.bank_account_no,
                        bank_ifsc_code: data?.bank_ifsc_code
                    }
                    setInitialValues({ ...initialValues, ...newValues });
                })
                toast.success(apiRes?.payload?.message);
            } else {
                toast.error(apiRes?.payload?.message);
            }
            setIsLoader(false);
            setIsModalOpen(false);
        },
    });

    useEffect(() => {
        dispatch(getBankDetails()).then((res) => {
            let { data } = res?.payload?.data
            let newValues = {
                bank_account_holder_name: data?.bank_account_holder_name,
                bank_name: data?.bank_name,
                bank_account_no: data?.bank_account_no,
                bank_ifsc_code: data?.bank_ifsc_code
            }
            setInitialValues({ ...initialValues, ...newValues })
        })
    }, [])

    console.log("bankData?.bank_account_holder_name", bankData?.bank_account_holder_name)

    return (
        <>
            <Modal show={isModalOpen} >
                <Modal.Header closeButton onClick={() => setIsModalOpen(false)} className="border-none"></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <div className="commmn-modal">
                                <h2>{(bankData?.bank_account_holder_name != null) ? `Edit` : `Add`} Bank Details</h2>
                                <Col lg={12}>
                                    <div className="form-set height-none">
                                        <Form.Label>Account Holder Name</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Enter account holder name here"
                                                name="bank_account_holder_name"
                                                aria-describedby="basic-addon1"
                                                value={formik.values.bank_account_holder_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        <div className="error-input-mt">
                                            <span className="text-danger">
                                                {formik.touched.bank_account_holder_name &&
                                                    formik.errors.bank_account_holder_name}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="form-set height-none">
                                        <Form.Label>Bank Name</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Enter bank name here"
                                                aria-describedby="basic-addon2"
                                                name="bank_name"
                                                value={formik.values.bank_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        <div className="error-input-mt">
                                            <span className="text-danger">
                                                {formik.touched.bank_name &&
                                                    formik.errors.bank_name}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="form-set height-none">
                                        <Form.Label>Account Number</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Enter account number here"
                                                aria-describedby="basic-addon3"
                                                name="bank_account_no"
                                                value={formik.values.bank_account_no}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        <div className="error-input-mt">
                                            <span className="text-danger">
                                                {formik.touched.bank_account_no &&
                                                    formik.errors.bank_account_no}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="form-set height-none">
                                        <Form.Label>IFSC code</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder="Enter IFSC code here"
                                                aria-describedby="basic-addon4"
                                                name="bank_ifsc_code"
                                                value={formik.values.bank_ifsc_code}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </InputGroup>
                                        <div className="error-input-mt">
                                            <span className="text-danger">
                                                {formik.touched.bank_ifsc_code &&
                                                    formik.errors.bank_ifsc_code}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <div className="d-flex justify-content-center">
                                    <button type='submit' className="btn-fill">
                                        {isLoader &&
                                            <>
                                                <div class="spinner-border spinner-border-sm" role="status" >
                                                </div>{" "}
                                            </>
                                        }
                                        Submit</button>
                                </div>
                            </div>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddBankShopDetails