import moment from 'moment';
import React, { useState, useEffect } from 'react';

const DeliveryPickupComp = ({ time }) => {
    // Calculate the difference between the selected time and the current time in seconds
    const calculateTimeLeft = () => {
        const selectedTime = moment(time).endOf('day'); // End of the selected day (midnight)
        const currentTime = moment(); // Current time
        const diffInSeconds = selectedTime.diff(currentTime, 'seconds'); // Difference in seconds
        return diffInSeconds > 0 ? diffInSeconds : 0; // If the time has passed, return 0
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft()); // Call the function to set initial state

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        return 0;
                    }
                    return prevTime - 1; // Decrement by 1 second
                });
            }, 1000); // 1-second interval

            return () => clearInterval(timer); // Cleanup on unmount
        }
    }, [timeLeft]);

    // Recalculate the time left when the `time` prop changes
    useEffect(() => {
        setTimeLeft(calculateTimeLeft());
    }, [time]);

    // Function to format time based on remaining time
    const formatTime = (seconds) => {
        if (seconds <= 0) return '--:--'; // No time left

        const days = Math.floor(seconds / (3600 * 24));
        const hrs = Math.floor((seconds % (3600 * 24)) / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        if (days > 0) {
            return `${days}d ${hrs}h ${mins < 10 ? '0' : ''}${mins}m ${secs < 10 ? '0' : ''}${secs}s`; // Days, Hours, Minutes, Seconds
        } else if (hrs > 0) {
            return `${hrs}h ${mins < 10 ? '0' : ''}${mins}m ${secs < 10 ? '0' : ''}${secs}s`; // Hours, Minutes, Seconds
        } else {
            return `${mins}m ${secs < 10 ? '0' : ''}${secs}s`; // Minutes, Seconds
        }
    };

    // Ensure that timeLeft is properly calculated before rendering the format
    return (
        <>
            {
                timeLeft > 0 ?
                    <div>{formatTime(timeLeft)}</div> :
                    <div>--:--</div>
            }
        </>
    );
};

export default DeliveryPickupComp;
