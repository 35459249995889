import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { Decryptedid } from "../utils/Becryprt";
import { Formik, Form as FormikForm, FieldArray, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { shopOpeningTiming } from "../Redux/Actions/shopAction";
import toast from "react-hot-toast";
import * as Yup from 'yup';
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageShopOpeningHoursEdit({
  formData,
  prevStep
}) {

  const { id } = useParams()
  const userId = id && Decryptedid(atob(id));

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    opening_hours: [
      { "day": "Monday", "from": "", "to": "" },
      { "day": "Tuesday", "from": "", "to": "" },
      { "day": "Wednesday", "from": "", "to": "" },
      { "day": "Thursday", "from": "", "to": "" },
      { "day": "Friday", "from": "", "to": "" },
      { "day": "Saturday", "from": "", "to": "" },
      { "day": "Sunday", "from": "", "to": "" }
    ]
  };

  const validationSchema = Yup.object().shape({
    opening_hours: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().required('Day is required*'),
        from: Yup.string().matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format').required('From time is required*'),
        to: Yup.string().matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format').required('To time is required*'),
      })
    )
  });

  const handleSubmit = (values) => {

    const filteredOpeningHours = values.opening_hours.filter(
      (entry) => entry.from && entry.to
    );

    console.log("filteredOpeningHours", filteredOpeningHours)
    // const formDataValue = new FormData();
    // formDataValue.append("opening_hours", JSON.stringify(filteredOpeningHours));
    dispatch(shopOpeningTiming({ id: userId, data: { "opening_hours": filteredOpeningHours } })).then((res) => {
      console.log(res, "444444444444444", filteredOpeningHours);
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.message)
        navigate("/ManageShop")
      } else {
        toast.error(res?.payload?.message)
      }
    });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageShopIcon}Manage Shop</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row className="mt-5">
            <Col lg={12} className="m-auto">
              <div className="login-cmn-box select-mainplan-box">
                <h2 className="text-center">Add Shop Opening Hours</h2>
                <Formik initialValues={initialValues}
                  onSubmit={handleSubmit}
                // validationSchema={validationSchema}
                >
                  {({ values, handleChange }) => (
                    <FormikForm>
                      <Row className="align-items-center mb-3">
                        <Col lg={10} className="mx-auto opening-hours-box">
                          <FieldArray name="opening_hours">
                            {({ insert, remove, push }) => (
                              <div>
                                {values.opening_hours.map((day, index) => (
                                  <Row key={index} className="align-items-center mb-3">
                                    <Col lg={2}>
                                      <p>{day.day}</p>
                                    </Col>
                                    <Col lg={5}>
                                      <Form.Control
                                        as="input"
                                        name={`opening_hours.${index}.from`}
                                        type="time"
                                        value={values.opening_hours[index].from}
                                        onChange={handleChange}
                                        placeholder="From"
                                      />
                                      <ErrorMessage name={`opening_hours.${index}.from`} component="div" className="text-danger" />
                                    </Col>
                                    <Col lg={5}>
                                      <Form.Control
                                        as="input"
                                        name={`opening_hours.${index}.to`}
                                        type="time"
                                        value={values.opening_hours[index].to}
                                        onChange={handleChange}
                                        placeholder="To"
                                      />
                                      <ErrorMessage name={`opening_hours.${index}.to`} component="div" className="text-danger" />
                                    </Col>
                                  </Row>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                      {/* <Link to="/ManageShop" className="submit filldetails-btn"> */}
                      <button type="submit" className="submit filldetails-btn">
                        Submit
                      </button>
                      {/* </Link> */}
                    </FormikForm>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
