import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { useDispatch } from "react-redux";
import { Field, Formik, Form as FormikForm } from "formik";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageShopSelectPlan({
  formData,
  setFormData,
  nextStep,
}) {
  const dispatch = useDispatch();

  const PlanSchema = Yup.object().shape({
    plan_type: Yup.string().nullable(),
    // shop_homemade: Yup.string().nullable(),
  });

  const validateForm = (values) => {
    const errors = {};

    if (!values.plan_type) {
      errors.plan_type =
        "Please choose any one option from Shops Pro or Shops Homemade";
      //   errors.shop_homemade =
      //     "Please choose atleast one option from Shops Pro or Shops Homemade";
    }

    return errors;
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageShopIcon}Add Shop</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row className="mt-5">
            <Col lg={12} className="m-auto">

              <div className="membership-progress">
                <div className="step-membership">
                  <ProgressBar now={25} />
                  <p>
                    <span>Step 1:</span> Select Membership Plan
                  </p>
                </div>
              </div>

              <div className="login-cmn-box ad-inner-width">
                <Row>
                  <Col lg={6}>
                    <div className="select-mainplan-box height-none">
                      <h2 className="text-center">Select Plan</h2>
                      <p className="text-center mt-3">
                        Choose a plan which describes you best
                      </p>
                      <Formik
                        initialValues={{
                          plan_type: formData.plan_type || "",
                          // shop_homemade: formData.shop_homemade || "",
                        }}
                        validationSchema={PlanSchema}
                        validate={validateForm}
                        onSubmit={(values) => {
                          const filteredValues = Object.fromEntries(
                            Object.entries(values).filter(([_, v]) => v !== "")
                          );

                          setFormData({
                            ...formData,
                            ...values,
                          });
                          nextStep();
                        }}
                      >
                        {({ errors, touched, values, setFieldValue }) => (
                          <FormikForm>
                            <div className="select-plan-box">
                              <p>Shops Pro with TVA number</p>

                              <div className="select-plan-item">
                                <p>
                                  Partner (Commission of 19% $1.50 to $2.50
                                  delivery)
                                </p>
                                <Field
                                  type="radio"
                                  name="plan_type"
                                  value="1"
                                  onChange={() => {
                                    setFieldValue("plan_type", "1");
                                    // setFieldValue("shop_homemade", "");
                                  }}
                                />
                                {/* {errors.plan_type && touched.plan_type ? (
                                  <div className="error">
                                    {errors.plan_type}
                                  </div>
                                ) : null} */}
                              </div>
                            </div>
                            <div className="select-plan-box mt-2">
                              <div className="select-plan-item">
                                <p>
                                  No Partner (Commission of 25% $3.5 delivery)
                                </p>
                                <Field
                                  type="radio"
                                  name="plan_type"
                                  value="2"
                                  onChange={() => {
                                    setFieldValue("plan_type", "2");
                                    // setFieldValue("shop_homemade", "");
                                  }}
                                />
                                {/* {errors.plan_type && touched.plan_type ? (
                                  <div className="error">
                                    {errors.plan_type}
                                  </div>
                                ) : null} */}
                              </div>
                            </div>
                            <div className="select-plan-box mt-3">
                              <p>Shops Homemade</p>
                              <div className="select-plan-item">
                                <p>
                                  No Partner (Commission of 25% $3.50 delivery)
                                </p>
                                {/* <Field
                                  type="radio"
                                  name="shop_homemade"
                                  value="1"
                                  onChange={() => {
                                    setFieldValue("plan_type", "");
                                    setFieldValue("shop_homemade", "1");
                                  }}
                                /> */}
                                <Field
                                  type="radio"
                                  id="plan_type_checkbox"
                                  name="plan_type"
                                  checked={values.plan_type === "3"}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setFieldValue(
                                      "plan_type",
                                      isChecked ? "3" : ""
                                    );
                                  }}
                                />

                                {/* {errors.shop_homemade && !values.plan_type && (
                                  <div className="error">
                                    {errors.shop_homemade}
                                  </div>
                                )} */}
                              </div>
                            </div>
                            <p className="text-danger">
                              {" "}
                              {errors.plan_type && touched.plan_type ? (
                                <div>{errors.plan_type}</div>
                              ) : null}
                            </p>

                            <button type="submit" className="submit">
                              Next
                            </button>
                          </FormikForm>
                        )}
                      </Formik>
                    </div>
                  </Col>
                  <Col lg={6} className="border-left">
                    <div className="select-plan-step">
                      <p>Steps to become a partner</p>
                      <ul>
                        <li>Our brand image on your window</li>
                        <p>
                          Lorem ipsum is simply dummy text for printing and
                          typesetting industry.
                        </p>
                        <li>Use of our packaging for cakes & breads </li>
                        <p>
                          Lorem ipsum is simply dummy text for printing and
                          typesetting industry.
                        </p>
                        <li>Social Media Collaboration</li>
                        <p>
                          Lorem ipsum is simply dummy text for printing and
                          typesetting industry.
                        </p>
                      </ul>
                    </div>

                    <div className="select-plan-step">
                      <p>You Receive</p>
                      <ul>
                        <li>Increased Visibility</li>
                        <li>Reduced delivery commission and delivery fee</li>
                        <li>Sponsorship opportunities</li>
                        <li>Promo Codes</li>
                        <li>
                          A partner receives a commission of 19% instead of 25%
                          and a reduced delivery fee. <Link>See More</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
