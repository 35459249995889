import React, { useEffect, useState } from "react";
import ManageShopSelectPlanEdit from "./ManageShopSelectPlanEdit";
import ManageShopAddCredentialsEdit from "./ManageShopAddCredentialsEdit";
import ManageShopAddImagesEdit from "./ManageShopAddImagesEdit";
import ManageShopOpeningHoursEdit from "./ManageShopOpeningHoursEdit";
import { shopDetailParticularById } from "../Redux/Actions/shopAction";
import { useParams } from "react-router-dom";
import { Decryptedid } from "../utils/Becryprt";
import { useDispatch } from "react-redux";

const MultiStepFormEdit = () => {

  const { id } = useParams();
  const shopId = id && Decryptedid(atob(id));

  const dispatch = useDispatch()

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    dispatch(shopDetailParticularById(shopId));
  }, [])

  switch (step) {
    case 1:
      return (
        <ManageShopSelectPlanEdit formData={formData} setFormData={setFormData} nextStep={nextStep} />
      );
    case 2:
      return (
        <ManageShopAddCredentialsEdit formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
      );
    case 3:
      return (
        <ManageShopAddImagesEdit formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
      );
    case 4:
      return (
        <ManageShopOpeningHoursEdit formData={formData} prevStep={prevStep} />
      );

    default:
      return null;
  }
};

export default MultiStepFormEdit;
