import React, { useState } from "react";
import ManageShopAddCredentials from "./ManageShopAddCredentials";
import ManageShopSelectPlan from "./ManageShopSelectPlan";
import ManageShopAddImages from "./ManageShopAddImages";
import ManageShopOpeningHours from "./ManageShopOpeningHours";
import ManageShopOpeningHoursAdd from "./ManageShopOpeningHoursAdd";

const MultiStepForm = () => {

const [step, setStep] = useState(1);
const [formData, setFormData] = useState({});

const nextStep = () => {
  setStep(step + 1);
};

const prevStep = () => {
  setStep(step - 1);
};

  switch (step) {
    case 1:
      return (
        <ManageShopSelectPlan formData={formData} setFormData={setFormData} nextStep={nextStep}/>
      );
    case 2:
      return (
        <ManageShopAddCredentials formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep}/>
      );
    case 3:
      return (
        <ManageShopAddImages formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep}/>
      );
    case 4:
      return (
        <ManageShopOpeningHoursAdd formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
      );

    default:
      return null;
  }
};

export default MultiStepForm;
