import axios from "axios";


const adminapi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

adminapi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  }
);

export default adminapi;
