import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, setCurrentPage, totalData, onPageChange, limit }) => {
    const totalPages = Math.ceil(totalData / limit);
    const items = [];
    
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => onPageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }

    return (
        <>
            {
                totalPages > 1 &&
                <Pagination>
                    <Pagination.First disabled={currentPage == 1 ? true : false} onClick={() => setCurrentPage(currentPage - 1)} />
                    {items}
                    <Pagination.Last disabled={totalPages == currentPage ? true : false} onClick={() => setCurrentPage(currentPage + 1)} />
                </Pagination>
            }
        </>
    );
};

export default PaginationComponent;