import React, { useContext, useEffect, useState } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import ManageShop from "./pages/ManageShop";
import Support from "./pages/Support";
import ManageOrdersDetail from "./pages/ManageOrdersDetail";
import Dashboard from "./pages/Dashboard";
import ManageOrders from "./pages/ManageOrders";
import ChangePassword from "./pages/ChangePassword";
import ManagerProfile from "./pages/ManagerProfile";
import ManageChat from "./pages/Support";
import Profile from "./pages/Profile";
import AllowLocation from "./pages/AllowLocation";
import ResetPassword from "./pages/ResetPassword";
import PasswordChanged from "./pages/PasswordChanged";
import CreateAccount from "./pages/CreateAccount";
import FillDetail from "./pages/FillDetail";
import AddShop from "./pages/AddShop";
import ManageShopSelectPlan from "./pages/ManageShopSelectPlan";
import ManageShopOpeningHours from "./pages/ManageShopOpeningHours";
import ManageShopAddImages from "./pages/ManageShopAddImages";
import ManageShopEdit from "./pages/ManageShopEdit";
import ManageShopAdd from "./pages/ManageShopAdd";
import ManageShopDetails from "./pages/ManageShopDetails";
import ManageShopAddCredentials from "./pages/ManageShopAddCredentials";
import Invoice from "./pages/Invoice";
import PrivateRoute from "./Routes/PrivateRoutes";
import MultiStepForm from "./pages/MultiStepFormShop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EditProductDetail from "./pages/EditProductDetail";
import ManageShopSelectPlanEdit from "./pages/ManageShopSelectPlanEdit";
import ManageShopAddCredentialsEdit from "./pages/ManageShopAddCredentialsEdit";
import ManageShopAddImagesEdit from "./pages/ManageShopAddImagesEdit";
import ManageShopOpeningHoursEdit from "./pages/ManageShopOpeningHoursEdit";
import MultiStepFormEdit from "./pages/MultiStepFormShopEdit";
import PastOrders from "./pages/PastOrders";
import Wallet from "./pages/Wallet";
import EditSelectPlan from "./pages/EditSelectPlan";
import EditShopDetails from "./pages/EditShopDetails";
import EditShopImages from "./pages/EditShopImages";
import EditOpeningHours from "./pages/EditOpeningHours";
import ManageShopOpeningHoursAdd from "./pages/ManageShopOpeningHoursAdd";
import { initializeApp } from "@firebase/app";
import { getMessaging, onMessage } from "@firebase/messaging";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { setSocket } from "./Redux/Reducers/socketSlice";

const firebaseConfig = {
  apiKey: "AIzaSyCpqa5f5gl95zw_iBmZWPPuCe0ENNOm_DQ",
  authDomain: "milcakepartner.firebaseapp.com",
  projectId: "milcakepartner",
  storageBucket: "milcakepartner.appspot.com",
  messagingSenderId: "626397822942",
  appId: "1:626397822942:web:bb1e2f1de742245203e74f"
};


function App() {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  const [socket, setsocket] = useState(null)
  const dispatch = useDispatch()


  useEffect(() => {
    let socketConnection;

    const token = localStorage.getItem('token'); 
    socketConnection = io('https://milcake-api.bosselt.com', {
      extraHeaders: {
        token: token,
      }
    })
    setsocket(socketConnection)
  }, [])

  console.log(socket, "conection")

  useEffect(() => {
    if (socket) {
      dispatch(setSocket(socket));
    }
  }, [socket])



  // useEffect(() => {
  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     toast.success(`${payload.notification.title}: ${payload.notification.body}`);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/LoginOtp/:email" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/CreateAccount" element={<CreateAccount />} />
          <Route path="/ResetPassword/:id" element={<ResetPassword />} />
          <Route path="/PasswordChanged" element={<PasswordChanged />} />
          <Route path="/FillDetail" element={<FillDetail />} />

          <Route element={<PrivateRoute />}>
            <Route path="/ManageChat" element={<ManageChat />} />
            <Route path="/ManageShop" element={<ManageShop />} />
            <Route path="/Support" element={<Support />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/ManageOrders" element={<ManageOrders />} />
            <Route path="/Wallet" element={<Wallet />} />
            <Route path="/PastOrders" element={<PastOrders />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/ManagerProfile" element={<ManagerProfile />} />
            <Route path="/AllowLocation" element={<AllowLocation />} />
            <Route path="/AddShop" element={<AddShop />} />
            <Route path="/Profile" element={<Profile />} />
            <Route
              path="/ManageOrdersDetail/:id"
              element={<ManageOrdersDetail />}
            />
            <Route path="/ManageShopEdit/:id" element={<ManageShopEdit />} />
            <Route
              path="/ManageShopProductDetail/:id"
              element={<ManageShopAdd />}
            />
            <Route
              path="/ManageShopDetails/:id"
              element={<ManageShopDetails />}
            />
            <Route
              path="/EditProductDetail/:id"
              element={<EditProductDetail />}
            />

            <Route
              path="/ManageShopSelectPlanEdit/:id"
              element={<ManageShopSelectPlanEdit />}
            />
            <Route
              path="/ManageShopAddCredentialsEdit/:id"
              element={<ManageShopAddCredentialsEdit />}
            />
            <Route
              path="/ManageShopAddImagesEdit/:id"
              element={<ManageShopAddImagesEdit />}
            />
            <Route
              path="/ManageShopOpeningHoursEdit/:id"
              element={<ManageShopOpeningHoursEdit />}
            />

            <Route path="/Invoice" element={<Invoice />} />
            <Route
              path="/ManageShopAddCredentials"
              element={<ManageShopAddCredentials />}
            />
            <Route
              path="/ManageShopAddImages"
              element={<ManageShopAddImages />}
            />
            <Route
              path="/ManageShopOpeningHoursAdd"
              element={<ManageShopOpeningHoursAdd />}
            />

            <Route
              path="/ManageShopOpeningHours/:id"
              element={<ManageShopOpeningHours />}
            />
            <Route path="/MultiStepForm" element={<MultiStepForm />} />

            <Route path="/MultiStepFormEdit/:id" element={<MultiStepFormEdit />} />
            <Route path="EditSelectPlan/:id" element={<EditSelectPlan />} />
            <Route path="EditShopDetails/:id" element={<EditShopDetails />} />
            <Route path="EditShopImages/:id" element={<EditShopImages />} />
            <Route path="EditOpeningHours/:id" element={<EditOpeningHours />} />
            <Route
              path="/ManageShopSelectPlan"
              element={<ManageShopSelectPlan />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
