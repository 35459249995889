import { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const useGetAddressIO = () => {
    const [addressData, setAddressData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getAddress = async (postcode) => {
        try {
            setLoading(true);
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${postcode}|country:BE&key=AIzaSyBY7WePV2Eg7cigEfbsFvjs1GzEHx6GXVA`);

            if (response?.data?.status != "OK") {
                setAddressData({
                    address: "",
                    postcode: "",
                    city: "",
                    lat: "",
                    lng: ""
                })
                return toast.error("This Postcode is not valid")
            } else {
                const addressResp = response?.data?.results[0]

                const addressComponents = addressResp?.address_components;
                const findLongNameByType = (type) => {
                    const component = addressComponents?.find(component =>
                        component?.types.includes(type)
                    );
                    return component ? component.long_name : null;
                };
                const cityName = findLongNameByType('locality') ||
                    findLongNameByType('administrative_area_level_2') || findLongNameByType('administrative_area_level_1')
                const { lat, lng } = addressResp?.geometry?.location;
                let newValues = {
                    address: addressResp?.formatted_address,
                    postcode: postcode,
                    city: cityName,
                    lat: lat,
                    lng: lng

                }
                setAddressData({ ...addressData, ...newValues });
            }
            setError(null);
        } catch (err) {
            setAddressData(null);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { addressData, error, loading, getAddress };
};

export default useGetAddressIO