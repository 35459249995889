import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const isLoggedIn = window.localStorage.getItem("isLoggedIn");
const token = window.localStorage.getItem("token");

const PrivateRoute = () => {
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;