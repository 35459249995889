import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from "recharts";

const colors = ["#a72a2f", "#8391a1",];

export default function WeeklyCustomerChart() {
  const [chartData, setChartData] = useState([
    { day: 'Sun', count: 120 },
    { day: 'Mon', count: 200 },
    { day: 'Tue', count: 150 },
    { day: 'Wed', count: 300 },
    { day: 'Thu', count: 250 },
    { day: 'Fri', count: 400 },
    { day: 'Sat', count: 350 },
  ]);

  // const CustomTooltip = ({ active, payload }) => {
  //   if (active && payload && payload.length) {
  //     const { day, count } = payload[0].payload; // Extract day and count for tooltip
  //     return (
  //       <div className="custom-tooltip">
  //         <p>{`Day: ${day}`}</p>
  //         <p>{`Customer Count: ${count}`}</p>
  //       </div>
  //     );
  //   }

  //   return null; // Return null if not active
  // };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData}>
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="count" name="Customers" radius={[10, 10, 0, 0]} barSize={50}>
          {
            chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
