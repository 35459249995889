import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik, Form as FormikForm } from "formik";
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageShopSelectPlanEdit({
  formData,
  setFormData,
  nextStep,
}) {
  const dispatch = useDispatch();

  const getShopDetail = useSelector((e) => e.shopAuthData.shopDetailById);

  const PlanSchema = Yup.object().shape({
    plan_type: Yup.string().nullable(),
    // shop_homemade: Yup.string().nullable(),
  });

  const validateForm = (values) => {
    const errors = {};

    if (!values.plan_type) {
      errors.plan_type =
        "Please choose atleast one option from Shops Pro or Shops Homemade";
      // errors.shop_homemade =
      //   "Please choose atleast one option from Shops Pro or Shops Homemade";
    }

    return errors;
  };

  const getChangedValues = (initialValues, currentValues) => {
    return Object.fromEntries(
      Object.entries(currentValues).filter(
        ([key, value]) => initialValues[key] !== value
      )
    );
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageShopIcon}Manage Shop</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row className="mt-5">
            <Col lg={12} className="m-auto">
              <div className="login-cmn-box ad-inner-width">
                <Row>
                  <Col lg={6}>
                    <div className="select-mainplan-box height-none">
                      <h2 className="text-center">Select Plan</h2>
                      <p className="text-center mt-3">
                        Choose a plan which describes you best
                      </p>
                      <Formik
                        initialValues={{
                          plan_type: getShopDetail?.plan_type?.toString() || "",
                          // shop_homemade:
                          //   getShopDetail?.shop_homemade?.toString() || 0,
                        }}
                        enableReinitialize={true}
                        validationSchema={PlanSchema}
                        validate={validateForm}
                        // onSubmit={(values) => {
                        //   const filteredValues = Object.fromEntries(
                        //     Object.entries(values).filter(([_, v]) => v !== "")
                        //   );

                        //   setFormData({
                        //     ...formData,
                        //     ...values,
                        //   });
                        //   nextStep();
                        // }}
                        onSubmit={(values, { setSubmitting }) => {
                          const changedValues = getChangedValues(
                            {
                              plan_type: getShopDetail?.plan_type?.toString() || "",
                              // shop_homemade: getShopDetail?.shop_homemade?.toString() || "",
                            },
                            values
                          );

                          setFormData((prevData) => ({
                            ...prevData,
                            ...changedValues,
                          }));
                          setSubmitting(false);
                          nextStep();
                        }}
                      >
                        {({ errors, touched, values, setFieldValue }) => (
                          <FormikForm>
                            <div className="select-plan-box">
                              <p>Shops Pro with TVA number</p>

                              <div className="select-plan-item">
                                <p>
                                  Partner (Commission of 19% $1.50 to $2.50
                                  delivery)
                                </p>
                                <Field
                                  type="radio"
                                  name="plan_type"
                                  value="1"
                                  checked={values["plan_type"] === "1"}
                                  onChange={() => {
                                    setFieldValue("plan_type", "1");
                                  }}
                                />
                              </div>
                            </div>
                            <div className="select-plan-box mt-2">
                              <div className="select-plan-item">
                                <p>
                                  No Partner (Commission of 25% $3.5 delivery)
                                </p>
                                <Field
                                  type="radio"
                                  name="plan_type"
                                  value="2"
                                  checked={values["plan_type"] === "2"}
                                  onChange={() => {
                                    setFieldValue("plan_type", "2");
                                  }}
                                />
                              </div>
                            </div>
                            <div className="select-plan-box mt-3">
                              <p>Shops Homemade</p>
                              <div className="select-plan-item">
                                <p>
                                  No Partner (Commission of 25% $3.50 delivery)
                                </p>

                                <Field
                                  type="radio"
                                  id="plan_type_checkbox"
                                  name="plan_type"
                                  checked={values.plan_type === "3"}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setFieldValue(
                                      "plan_type",
                                      isChecked ? "3" : 0
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <p className="text-danger">
                              {" "}
                              {errors.plan_type && touched.plan_type ? (
                                <div>{errors.plan_type}</div>
                              ) : null}
                            </p>

                            <button type="submit" className="submit">
                              Continue
                            </button>
                          </FormikForm>
                        )}
                      </Formik>
                    </div>
                  </Col>
                  <Col lg={6} className="border-left">
                    <div className="select-plan-step">
                      <p>Steps to become a partner</p>
                      <ul>
                        <li>Our brand image on your window</li>
                        <p>
                          Lorem ipsum is simply dummy text for printing and
                          typesetting industry.
                        </p>
                        <li>Use of our packaging for cakes & breads </li>
                        <p>
                          Lorem ipsum is simply dummy text for printing and
                          typesetting industry.
                        </p>
                        <li>Social Media Collaboration</li>
                        <p>
                          Lorem ipsum is simply dummy text for printing and
                          typesetting industry.
                        </p>
                      </ul>
                    </div>

                    <div className="select-plan-step">
                      <p>You Receive</p>
                      <ul>
                        <li>Increased Visibility</li>
                        <li>Reduced delivery commission and delivery fee</li>
                        <li>Sponsorship opportunities</li>
                        <li>Promo Codes</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
