import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import { ErrorMessage, Formik, Form as FormikForm } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { shopOpeningTiming } from "../Redux/Actions/shopAction";
import toast from "react-hot-toast";
import { Decryptedid, Encryptedid } from "../utils/Becryprt";
import * as Yup from "yup";
import NotificationComp from "../Components/common/NotificationComp";

export default function ManageShopAddImagesEdit({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) {
  const { id } = useParams();
  const shopId = id && Decryptedid(atob(id));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);

  const getShopDetail = useSelector((e) => e.shopAuthData.shopDetailById);


  const PlanSchema = Yup.object().shape({
    // banner_image: Yup.mixed()
    //   .required("Banner image is required")
    //   .test("fileSize", "banner image size is too large", (value) => {
    //     if (!value) return true;
    //     return value && value.size <= 25000000; // 25 MB limit (in bytes)
    //   }),
    // shop_image: Yup.mixed()
    //   .required("Shop image is required")
    //   .test("fileSize", "sop image size is too large", (value) => {
    //     if (!value) return true;
    //     return value && value.size <= 25000000; // 25 MB limit (in bytes)
    //   }),
  });

  const handleFileChange = (event, setFieldValue, type) => {
    const selectedFile = event.currentTarget.files[0];
    if (selectedFile) {
      if (type === "banner_image") {
        setFile(selectedFile);
      } else if (type === "shop_image") {
        setFile1(selectedFile);
      }
      setFieldValue(type, selectedFile);
    }
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageShopIcon}Manage Shop</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row className="mt-5">
            <Col lg={12} className="m-auto">
              <div className="login-cmn-box ad-inner-width">
                <Row>
                  <Col lg={12}>
                    <div className="select-mainplan-box height-none">
                      <h2 className="text-center">Add Images</h2>
                    </div>
                  </Col>
                  <Col lg={10} className="mx-auto  opening-hours-box">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        banner_image: getShopDetail?.banner_image || "",
                        shop_image: getShopDetail?.shop_image || "",
                      }}
                      validationSchema={PlanSchema}

                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);

                        const formDataToSubmit = new FormData();

                        if (
                          values.banner_image !== getShopDetail?.banner_image
                        ) {
                          formDataToSubmit.append(
                            "banner_image",
                            values.banner_image
                          );
                        }
                        if (values.shop_image !== getShopDetail?.shop_image) {
                          formDataToSubmit.append(
                            "shop_image",
                            values.shop_image
                          );
                        }

                        if (formData.formDataImage) {
                          formDataToSubmit.append(
                            "govt_id",
                            formData.formDataImage.get("govt_id")
                          );
                        }

                        Object.keys(formData).forEach((key) => {
                          if (
                            (key !== "file" &&
                              key !== "formDataImage" &&
                              formData[key]) ||
                            formData["shop_homemade"] === 0
                          ) {
                            formDataToSubmit.append(key, formData[key]);
                          }
                        });

                        console.log(formData, "Asdasdasdasdasd");

                        dispatch(
                          shopOpeningTiming({
                            id: shopId,
                            data: formDataToSubmit,
                          })
                        )
                          .then((res) => {
                            setSubmitting(false);
                            let id = res?.payload?.data?._id;

                            if (res?.payload?.status === 200 && id) {
                              toast.success(res?.payload?.message);
                              navigate(
                                `/ManageShopOpeningHours/${Encryptedid(id)}`
                              );
                            } else {
                              toast.error(res?.payload?.message);
                            }
                          })
                          .catch((error) => {
                            setSubmitting(false);
                            toast.error("An error occurred. Please try again.");
                          });
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        touched,
                        isSubmitting,
                        handleBlur,
                        setFieldValue,
                        isValid,
                      }) => (
                        <FormikForm>
                          {console.log(values, "sdfsdfsdfsd324234")}
                          <Row className="align-items-center mb-3">
                            <Col lg={12}>
                              <div className="image-upd-shop">
                                <Form.Label htmlFor="inputPassword5">
                                  Banner Image
                                </Form.Label>
                                <div className="upld-box-shop">
                                  <Form.Control
                                    type="file"
                                    name="banner_image"
                                    onChange={(event) =>
                                      handleFileChange(
                                        event,
                                        setFieldValue,
                                        "banner_image"
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="banner_image"
                                    className="text-danger"
                                    component="div"
                                  />

                                  {file ? (
                                    <>
                                      {file.type.startsWith("image/") && (
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt="Selected file"
                                        />
                                      )}
                                    </>
                                  ) : values.banner_image ? (
                                    <>
                                      {typeof values.banner_image ===
                                        "string" ? (
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}public/${values?.banner_image}`}
                                          alt="Banner Image"
                                        />
                                      ) : (
                                        values.banner_image.type.startsWith(
                                          "image/"
                                        ) && (
                                          <img
                                            src={URL.createObjectURL(
                                              values.banner_image
                                            )}
                                            alt="Selected file"
                                          />
                                        )
                                      )}
                                    </>
                                  ) : null}

                                  <svg
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="44"
                                      height="44"
                                      rx="22"
                                      fill="#F5F5F5"
                                    />
                                    <path
                                      d="M18.9975 27.75C18.5875 27.75 18.2475 27.41 18.2475 27V22.81L17.5275 23.53C17.2375 23.82 16.7575 23.82 16.4675 23.53C16.1775 23.24 16.1775 22.76 16.4675 22.47L18.4675 20.47C18.6775 20.26 19.0075 20.19 19.2875 20.31C19.5675 20.42 19.7475 20.7 19.7475 21V27C19.7475 27.41 19.4075 27.75 18.9975 27.75Z"
                                      fill="#757575"
                                    />
                                    <path
                                      d="M20.9975 23.7499C20.8075 23.7499 20.6175 23.6799 20.4675 23.5299L18.4675 21.5299C18.1775 21.2399 18.1775 20.7599 18.4675 20.4699C18.7575 20.1799 19.2375 20.1799 19.5275 20.4699L21.5275 22.4699C21.8175 22.7599 21.8175 23.2399 21.5275 23.5299C21.3775 23.6799 21.1875 23.7499 20.9975 23.7499Z"
                                      fill="#757575"
                                    />
                                    <path
                                      d="M25 32.75H19C13.57 32.75 11.25 30.43 11.25 25V19C11.25 13.57 13.57 11.25 19 11.25H24C24.41 11.25 24.75 11.59 24.75 12C24.75 12.41 24.41 12.75 24 12.75H19C14.39 12.75 12.75 14.39 12.75 19V25C12.75 29.61 14.39 31.25 19 31.25H25C29.61 31.25 31.25 29.61 31.25 25V20C31.25 19.59 31.59 19.25 32 19.25C32.41 19.25 32.75 19.59 32.75 20V25C32.75 30.43 30.43 32.75 25 32.75Z"
                                      fill="#757575"
                                    />
                                    <path
                                      d="M32 20.75H28C24.58 20.75 23.25 19.42 23.25 16V12C23.25 11.7 23.43 11.42 23.71 11.31C23.99 11.19 24.31 11.26 24.53 11.47L32.53 19.47C32.74 19.68 32.81 20.01 32.69 20.29C32.57 20.57 32.3 20.75 32 20.75ZM24.75 13.81V16C24.75 18.58 25.42 19.25 28 19.25H30.19L24.75 13.81Z"
                                      fill="#757575"
                                    />
                                  </svg>
                                  <p className="mt-2">
                                    <span>Click to Upload</span> or drag and
                                    drop
                                  </p>
                                  <p> (Max. File size: 25 MB)</p>
                                </div>
                                <p className="mt-2">
                                  * Images must be 16:9 1920x1080.
                                </p>
                              </div>
                            </Col>
                            <Col lg={6} className="mt-4">
                              <div className="image-upd-shop">
                                <Form.Label htmlFor="inputPassword5">
                                  Shop Image
                                </Form.Label>
                                <div className="upld-box-shop">
                                  <Form.Control
                                    type="file"
                                    name="shop_image"
                                    onChange={(event) =>
                                      handleFileChange(
                                        event,
                                        setFieldValue,
                                        "shop_image"
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name="shop_image"
                                    className="text-danger"
                                    component="div"
                                  />

                                  {file1 ? (
                                    <>
                                      {file1.type.startsWith("image/") && (
                                        <img
                                          src={URL.createObjectURL(file1)}
                                          alt="Selected file"

                                        />
                                      )}
                                    </>
                                  ) : values.shop_image ? (
                                    <>
                                      {typeof values.shop_image === "string" ? (
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}public/${values?.shop_image}`}
                                          alt="Shop Image"

                                        />
                                      ) : (
                                        values.shop_image.type.startsWith(
                                          "image/"
                                        ) && (
                                          <img
                                            src={URL.createObjectURL(
                                              values.shop_image
                                            )}
                                            alt="Selected file"

                                          />
                                        )
                                      )}
                                    </>
                                  ) : null}

                                  <svg
                                    width="44"
                                    height="44"
                                    viewBox="0 0 44 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="44"
                                      height="44"
                                      rx="22"
                                      fill="#F5F5F5"
                                    />
                                    <path
                                      d="M18.9975 27.75C18.5875 27.75 18.2475 27.41 18.2475 27V22.81L17.5275 23.53C17.2375 23.82 16.7575 23.82 16.4675 23.53C16.1775 23.24 16.1775 22.76 16.4675 22.47L18.4675 20.47C18.6775 20.26 19.0075 20.19 19.2875 20.31C19.5675 20.42 19.7475 20.7 19.7475 21V27C19.7475 27.41 19.4075 27.75 18.9975 27.75Z"
                                      fill="#757575"
                                    />
                                    <path
                                      d="M20.9975 23.7499C20.8075 23.7499 20.6175 23.6799 20.4675 23.5299L18.4675 21.5299C18.1775 21.2399 18.1775 20.7599 18.4675 20.4699C18.7575 20.1799 19.2375 20.1799 19.5275 20.4699L21.5275 22.4699C21.8175 22.7599 21.8175 23.2399 21.5275 23.5299C21.3775 23.6799 21.1875 23.7499 20.9975 23.7499Z"
                                      fill="#757575"
                                    />
                                    <path
                                      d="M25 32.75H19C13.57 32.75 11.25 30.43 11.25 25V19C11.25 13.57 13.57 11.25 19 11.25H24C24.41 11.25 24.75 11.59 24.75 12C24.75 12.41 24.41 12.75 24 12.75H19C14.39 12.75 12.75 14.39 12.75 19V25C12.75 29.61 14.39 31.25 19 31.25H25C29.61 31.25 31.25 29.61 31.25 25V20C31.25 19.59 31.59 19.25 32 19.25C32.41 19.25 32.75 19.59 32.75 20V25C32.75 30.43 30.43 32.75 25 32.75Z"
                                      fill="#757575"
                                    />
                                    <path
                                      d="M32 20.75H28C24.58 20.75 23.25 19.42 23.25 16V12C23.25 11.7 23.43 11.42 23.71 11.31C23.99 11.19 24.31 11.26 24.53 11.47L32.53 19.47C32.74 19.68 32.81 20.01 32.69 20.29C32.57 20.57 32.3 20.75 32 20.75ZM24.75 13.81V16C24.75 18.58 25.42 19.25 28 19.25H30.19L24.75 13.81Z"
                                      fill="#757575"
                                    />
                                  </svg>
                                  <p className="mt-2">
                                    <span>Click to Upload</span> or drag and
                                    drop
                                  </p>
                                  <p> (Max. File size: 25 MB)</p>
                                </div>
                                <p className="mt-2">
                                  * Images must be 16:9 1920x1080.
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <button
                            variant="primary"
                            type="submit"
                            className="submit filldetails-btn"
                          >
                            Continue
                          </button>
                        </FormikForm>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
