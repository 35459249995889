import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { month: 'Jan', revenue2023: 4000, revenue2024: 4500 },
  { month: 'Feb', revenue2023: 3000, revenue2024: 3200 },
  { month: 'Mar', revenue2023: 3500, revenue2024: 1700 },
  { month: 'Apr', revenue2023: 2000, revenue2024: 2500 },
  { month: 'May', revenue2023: 4500, revenue2024: 4800 },
  { month: 'Jun', revenue2023: 1700, revenue2024: 3900 },
  { month: 'Jul', revenue2023: 3200, revenue2024: 3500 },
  { month: 'Aug', revenue2023: 4100, revenue2024: 1400 },
  { month: 'Sep', revenue2023: 1900, revenue2024: 4200 },
  { month: 'Oct', revenue2023: 4300, revenue2024: 1500 },
  { month: 'Nov', revenue2023: 1800, revenue2024: 4900 },
  { month: 'Dec', revenue2023: 5200, revenue2024: 5400 },
];

const RevenueComparisonChart = () => {
  const formatCurrency = (value) => {
    return `$${value}`;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data}>
        <XAxis dataKey="month" />
        <YAxis tickFormatter={formatCurrency} />
        <Tooltip formatter={(value) => [formatCurrency(value), 'Revenue']} />
        <Legend />
        <Line type="monotone" dataKey="revenue2023" stroke="#000000" dot={false} strokeWidth={3}  />
        <Line type="monotone" dataKey="revenue2024" stroke="#a72a2f" dot={false} strokeWidth={3}  />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RevenueComparisonChart;
