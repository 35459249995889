import React from 'react'
import Select from "react-select";


const SubCategoryComp = ({ selectedSubCategory, subCategoryoptions, handleChangeSubCategory }) => {
  console.log("selectedSubCategory", selectedSubCategory)
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#fbd9d3" : "#fff",
            color: "#333",
            cursor: "pointer",
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    return (
        <>
            <div className="form-set height-none">
                <Select
                    className="mb-3 react-select-set"
                    styles={customStyles}
                    name="subcategory_id"
                    value={selectedSubCategory}
                    options={subCategoryoptions}
                    placeholder="Select Sub Category"
                    onChange={handleChangeSubCategory}
                />
            </div>
        </>
    )
}

export default SubCategoryComp