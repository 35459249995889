import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changePassword } from "../Redux/Actions/auth";
import toast from "react-hot-toast";
import Icons from "../Assets/icons";
import { InputGroup } from "react-bootstrap";
import NotificationComp from "../Components/common/NotificationComp";

export default function ChangePassword() {
  const dispatch = useDispatch();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password is required*"),
      newPassword: Yup.string()
        .min(6, "Password must be at least 6 characters*")
        .required("New Password is required*"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match*")
        .required("Confirm Password is required*"),
    }),

    onSubmit: (values, { resetForm }) => {
      let objData = {
        old_password: values.oldPassword,
        new_password: values.newPassword,
      };
      dispatch(changePassword(objData)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          resetForm();
        } else {
          toast.error(res?.payload?.message);
        }
      });
    },
  });

  return (
    <>
      <Layout>
        {/* <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div> */}
        <div className="right-top">
          <div className="heading-top">
            <div className="common-head">
              <h2>{Icons.Toplock}Change Password</h2>
            </div>
            <div className="common-icon-side">
              <NotificationComp />
            </div>
          </div>
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new login-box-inner-wrap">
                <h2 className="text-center mt-0">Change Password</h2>

                <Form
                  className="change-password-form"
                  onSubmit={formik.handleSubmit}
                >
                  {/* <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridOldPassword">
                      <Form.Label>Old Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Old Password"
                        name="oldPassword"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.oldPassword &&
                          formik.errors.oldPassword
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.oldPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridNewPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.newPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridConfirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row> */}

                  <div className="form-set height-none">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                        >
                          <path
                            d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03057C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        className="border-right-o"
                        type={showOldPassword ? "text" : "password"}
                        placeholder="Old Password"
                        name="oldPassword"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.oldPassword &&
                          formik.errors.oldPassword
                        }
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowOldPassword((prev) => !prev)}
                      >
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                          alt="eye icon"
                        />
                      </button>
                    </InputGroup>
                    <div className="error-input-mt">
                      <span className="text-danger">
                        {formik.touched.oldPassword &&
                          formik.errors.oldPassword}
                      </span>
                    </div>
                    <Form.Label>New Password</Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                        >
                          <path
                            d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03057C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        className="border-right-o"
                        type={showNewPassword ? "text" : "password"}
                        placeholder="New Password"
                        name="newPassword"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.newPassword &&
                          formik.errors.newPassword
                        }
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowNewPassword((prev) => !prev)}
                      >
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                          alt="eye icon"
                        />
                      </button>
                    </InputGroup>
                    <div className="error-input-mt">
                      <span className="text-danger">
                        {formik.touched.newPassword &&
                          formik.errors.newPassword}
                      </span>
                    </div>
                    <Form.Label>Confirm Password</Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                        >
                          <path
                            d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03057C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>
                      <Form.Control
                        className="border-right-o"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                        }
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                          alt="eye icon"
                        />
                      </button>
                    </InputGroup>
                    <div className="error-input-mt">
                      <span className="text-danger">
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword}
                      </span>
                    </div>
                  </div>
                  <Form.Group className="mt-4 mb-5 model-btn text-center">
                    <div className="cmn-btn">
                      <Button type="submit">SAVE</Button>
                    </div>
                  </Form.Group>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
