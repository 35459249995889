
// token 
export const getToken = () => {
    const token = localStorage.getItem("token");
    return token ? token : null;
  };
  
 export const today = new Date().toISOString().split('T')[0];

 const getYesterday = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today.toISOString().split('T')[0];
};

 export const yesterday = getYesterday();

 const getFourteenYearsAgo = () => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - 14);
  return today.toISOString().split('T')[0];
};

export const fourteenYearsAgo = getFourteenYearsAgo();


 const getLastDayOfPreviousYear = () => {
  const today = new Date();
  const lastDayOfPreviousYear = new Date(today.getFullYear() - 1, 11, 31);
  return lastDayOfPreviousYear.toISOString().split('T')[0];
};

  export const lastDayOfPreviousYear = getLastDayOfPreviousYear();