import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import Icons from "../Assets/icons";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import { getOrderList } from "../Redux/Actions/orderAction";
import { useQuery } from "../utils/commonFunction";
import OrderAcceptRejectModal from "../Components/Layout/Modals/OrderAcceptRejectModal";
import { Dropdown, Form } from "react-bootstrap";
import ManageOrderList from "../Components/common/ManageOrderList";
import NotificationComp from "../Components/common/NotificationComp";
import { getShopList } from "../Redux/Actions/shopAction";
import PaginationComponent from "../Components/common/Pagination";

export default function ManageOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();
  const orderStatus = query.get("status");
  const deliveryType = query.get("type");
  const { orderList } = useSelector((state) => state?.orderAuthData);
  const [modalShow, setModalShow] = useState(false);
  const [modalState, setModalState] = useState({
    id: "",
    type: ""
  });
  const dropdownRef = useRef(null);
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const getShopDetailList = useSelector((e) => e.shopAuthData.shopListDetail);
  const [selectedShopId, setSelectedShopId] = useState('');

  const { setSocket } = useSelector((state) => state.socket);

  useEffect(() => {
    if (setSocket) {
      setSocket.on('order_request', async (res) => {
        window.location.reload();
        // await dispatch(getOrderList({
        //   status: orderStatus,
        //   type: deliveryType,
        //   pagination: currentPage - 1,
        //   limit: limit,
        //   shop_id: selectedShopId
        // }));
      });
      return () => {
        setSocket.off('order_request');
      };
    }
  }, [setSocket]);


  useEffect(() => {
    getApiCall()
  }, [currentPage, limit, dispatch, orderStatus, deliveryType, selectedShopId]);

  const getApiCall = (options = {}) => {
    const { page = currentPage, limitValue = limit } = options;
    dispatch(getOrderList({
      status: orderStatus,
      type: deliveryType,
      pagination: page - 1,
      limit: limitValue,
      shop_id: selectedShopId
    }));
  };

  const handleConfirm = (type, res) => {
    let newValue = {
      id: res?._id,
      type
    }
    setModalState({ ...modalState, ...newValue })
    setModalShow(true)
  }

  const ChangeStatusButton = (newStatus) => {
    const searchParams = new URLSearchParams(window?.location?.search);
    searchParams.set('status', newStatus);
    navigate(`/ManageOrders?${searchParams.toString()}`);
  };

  const ChangeDeliveryType = (newStatus) => {
    const searchParams = new URLSearchParams(window?.location?.search);
    searchParams.set('type', newStatus);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleToogle = () => {
    setDropdownToggle(!dropdownToggle);
  };

  const handleToogleClose = () => {
    setDropdownToggle(false);
  };

  const handleFilterChange = async (filterValue) => {
    if (selectedFilter === filterValue) {
      clearFilter();
    } else {
      setSelectedFilter(filterValue);
      await applyFilter(filterValue);
    }
  };

  const applyFilter = async (filterValue) => {
    let payload = {
      status: orderStatus,
      filter_by: filterValue == 0 ? "" : filterValue,
    };
    await dispatch(getOrderList(payload));
    handleToogleClose();
  };

  const clearFilter = async () => {
    setSelectedFilter("");
    await dispatch(getOrderList({ status: activeTabIndex === 1 ? `1` : `2` }));
    handleToogleClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleToogleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [])


  useEffect(() => {
    dispatch(getShopList());
  }, []);

  const handleSelectChange = (event) => {
    const shopId = event.target.value;
    if (shopId) {
      setSelectedShopId(shopId == "all" ? "" : shopId);
    }
  };

  console.log("selectedShopId", selectedShopId)

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.ManageOrdersIcon}Manage Orders</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>

      <Container fluid className="px-0">
        <div className="dashboard-items">
          <Row>
            <Col lg={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey={orderStatus}>
                <Row>
                  <Col sm={12} className="mb-3">
                    <Row>
                      <Col xl={5} lg={5} md={12} sm={12} xs={12} className="mb-3">
                        <Nav variant="pills" className="manage-orders-tabs">
                          <Nav.Item>
                            <Nav.Link eventKey={1} onClick={() => ChangeStatusButton(1)}>Order Request</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey={2} onClick={() => ChangeStatusButton(2)}> Accepted</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey={3} onClick={() => ChangeStatusButton(3)}>Declined</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col xl={7} lg={7} md={12} sm={12} xs={12}>


                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey={deliveryType}
                        >
                          <Row>
                            <Col sm={12} className="mb-3">

                              <div className="order-filter-set">
                                <div className="shop-select-by">
                                  <Form.Select aria-label="Select a shop" onChange={handleSelectChange}>
                                    <option value="">Select shop</option>
                                    <option value="all">All</option>
                                    {Array.isArray(getShopDetailList) &&
                                      getShopDetailList.length > 0 &&
                                      getShopDetailList.map((res) => (
                                        <option key={res._id} value={res._id}>
                                          {res.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </div>
                                <Nav
                                  variant="pills"
                                  className="delivery-orders-tabs"
                                >
                                  <Nav.Item>
                                    <Nav.Link eventKey={1} onClick={() => ChangeDeliveryType(1)}>Delivery</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey={2} onClick={() => ChangeDeliveryType(2)}>Takeaway</Nav.Link>
                                  </Nav.Item>
                                </Nav>

                                {/* Filter DropDown */}
                                <div className="filter-dropdowns">
                                  <Dropdown ref={dropdownRef}>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img
                                        src={require("../Assets/Images/filters.svg").default}
                                        alt="Filter"
                                        onClick={handleToogle}
                                      />
                                      Filters
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu show={dropdownToggle}>
                                      {["All", "Few days back", "One Week Ago", "One month Ago", "Three month Ago"].map(
                                        (filterText, index) => {
                                          const filterValues = [0, 1, 2, 3, 4];
                                          const filterValue = filterValues[index];

                                          return (
                                            <div className="filters filter-choose-set" key={index}>
                                              <p>{filterText}</p>
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="flexRadioDefault"
                                                checked={selectedFilter === filterValue}
                                                onChange={() => handleFilterChange(filterValue)}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>

                              </div>
                            </Col>
                            {/* <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="first"></Tab.Pane>
                                <Tab.Pane eventKey="second"></Tab.Pane>
                              </Tab.Content>
                            </Col> */}
                          </Row>
                        </Tab.Container>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="mb-5">
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <ManageOrderList orderList={orderList} handleConfirm={handleConfirm} orderStatus={orderStatus} deliveryType={deliveryType} currentPage={currentPage} setCurrentPage={setCurrentPage} handlePageChange={handlePageChange} limit={limit} />
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <ManageOrderList orderList={orderList} handleConfirm={handleConfirm} orderStatus={orderStatus} deliveryType={deliveryType} currentPage={currentPage} setCurrentPage={setCurrentPage} handlePageChange={handlePageChange} limit={limit} />
                      </Tab.Pane>
                      <Tab.Pane eventKey={3}>
                        <ManageOrderList orderList={orderList} handleConfirm={handleConfirm} orderStatus={orderStatus} deliveryType={deliveryType} currentPage={currentPage} setCurrentPage={setCurrentPage} handlePageChange={handlePageChange} limit={limit} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </div >
        {/* Pagination */}
        <div className="d-flex justify-content-center pagination-set">
          <PaginationComponent
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalData={orderList?.count || 0}
            limit={limit}
            onPageChange={handlePageChange}
          />
        </div>


      </Container >

      {/* OrderAcceptRejectModal */}
      <OrderAcceptRejectModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={modalState}
        orderStatus={orderStatus}
        page="List"
      />

    </Layout >
  );
}
