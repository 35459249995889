import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userSighUpAction } from "../Redux/Actions/auth";
import toast from "react-hot-toast";
import { Encryptedid } from "../utils/Becryprt";

export default function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [checked, setIsChecked] = useState(false);

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = "Email is required*";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email format";
    }

    if (!password) {
      newErrors.password = "Password is required*";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required*";
    } else if (confirmPassword !== password) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      console.log(email, password, "234234234");
      // navigate("/FillDetail");

      let userData = {
        role: 3,
        email: email,
        password: password,
      };

      if (!checked) {
        toast.error("Please Choose Terms & Conditions.");
        return;
      }

      dispatch(userSighUpAction(userData)).then((res) => {
        console.log(res, "sdfsdfsdfsdfsdf");
        if (res?.payload?.status === 201) {
          localStorage.setItem("userEmail", email);
          navigate(`/LoginOtp/${Encryptedid(email)}`);
        } else if (res?.payload?.status === 409) {
          console.log(res, "sdfsdfsdfsdfsdf");
          toast.error(res?.payload?.message);
        } else if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate(`/LoginOtp/${Encryptedid(email)}`);
        } else {
          toast.error(res?.payload?.error_description);
        }
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (errors.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    }
  };

  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
    if (errors.checked) {
      setErrors((prevErrors) => ({ ...prevErrors, checked: "" }));
    }
  };

  return (
    <div className="login-bg">
      {/* <div className="">
        <img
          src={require("../Assets/Images/cake-l-b.png")}
          className="cake-l-b"
        />
        <img
          src={require("../Assets/Images/cake-r-t.png")}
          className="cake-r-t"
        />
        <img
          src={require("../Assets/Images/cake-r-b.png")}
          className="cake-r-b"
        />
      </div> */}
      <Container fluid>
        <Row className="box-center">
          <Col lg={7} className="p-0">
            <div className="sign-left-img">
              <img src={require("../Assets/Images/sign-banner.png")} />
            </div>
          </Col>
          <Col lg={5} className="">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Create an Account</h2>
                <p className="text-center mt-3">
                  Create an account to continue
                </p>
                <Form onSubmit={handleSubmit}>
                  <div className="form-set">
                    <Form.Label>Email</Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                        >
                          <path
                            d="M8.99152 13.9951C7.07519 13.9951 5.15886 14.0087 3.24309 13.9906C1.70014 13.9759 0.268368 12.6922 0.0454608 11.163C0.0201942 10.9887 0.00166537 10.8115 0.00166537 10.636C-0.00114203 8.18593 -1.90675e-05 5.73642 0.00222685 3.28691C0.00278833 2.81461 0.129121 2.36996 0.328447 1.94563C0.434005 1.72161 0.494083 1.71146 0.667019 1.88469C1.19313 2.41172 1.71811 2.94045 2.24366 3.46804C3.49295 4.72299 4.72933 5.99091 5.99546 7.22836C7.66362 8.85911 10.3116 8.87604 11.9803 7.24472C13.7551 5.50902 15.4895 3.73156 17.2419 1.97215C17.279 1.93491 17.3149 1.8971 17.3525 1.86099C17.4957 1.72444 17.5597 1.73177 17.6462 1.90444C17.8539 2.31805 17.9898 2.75706 17.9915 3.22033C17.9982 5.72909 18.0072 8.23785 17.9904 10.746C17.9797 12.2995 16.6821 13.7418 15.1476 13.9517C14.9163 13.9833 14.6804 13.9957 14.4469 13.9963C12.6282 13.9996 10.8102 13.9979 8.99152 13.9979C8.99152 13.9979 8.99152 13.9968 8.99152 13.9951Z"
                            fill="#8391A1"
                          />
                          <path
                            d="M9.00051 0.00114491C10.9107 0.00114491 12.8203 -0.00280441 14.7304 0.00396687C15.3581 0.00622397 15.9309 0.212183 16.4547 0.554697C16.6316 0.670373 16.6366 0.720029 16.4867 0.871254C15.6995 1.66462 14.9112 2.4563 14.1235 3.24854C13.151 4.22642 12.1796 5.20431 11.2054 6.18107C9.93313 7.45745 8.0634 7.45689 6.7894 6.17768C5.03646 4.41828 3.28632 2.65661 1.53563 0.896082C1.35034 0.709871 1.35371 0.674324 1.5783 0.532127C2.10104 0.200334 2.66926 0.00452962 3.29025 0.0028368C5.19367 -0.00224166 7.09709 0.00114491 9.00051 0.00114491Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>

                      <Form.Control
                        className="border-right-o"
                        placeholder="Email Address"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </InputGroup>
                    <div className="error-input-mt">
                      <span className="text-danger">
                        {errors.email && errors.email}
                      </span>
                    </div>
                    <Form.Label>New Password</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                        >
                          <path
                            d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03056C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>

                      <Form.Control
                        className="border-right-o"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                          alt="eye icon"
                        />
                      </button>
                    </InputGroup>
                    <div className="error-input-mt">
                      <span className="text-danger">
                        {errors.password && errors.password}
                      </span>
                    </div>
                    <Form.Label>Confirm Password</Form.Label>

                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          fill="none"
                        >
                          <path
                            d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03056C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                            fill="#8391A1"
                          />
                        </svg>
                      </InputGroup.Text>

                      <Form.Control
                        className="border-right-o"
                        type={showPassword1 ? "text" : "password"}
                        placeholder="Confirm Password"
                        aria-label="Confirm Password"
                        aria-describedby="basic-addon1"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <button
                        type="button"
                        className="pass-show"
                        onClick={() => setShowPassword1((prev) => !prev)}
                      >
                        <img
                          src={require("../Assets/Images/eye.svg").default}
                          alt="eye icon"
                        />
                      </button>
                    </InputGroup>
                    <div className="error-input-mt">
                      <span className="text-danger">
                        {errors.confirmPassword && errors.confirmPassword}
                      </span>
                    </div>
                  </div>

                  <div className="accept-condition">
                    <input
                      type="checkbox"
                      name="termandcondition"
                      onChange={handleChangeCheckbox}
                    />

                    <p>
                      By creating an account I’m agreeing to the{" "}
                      <Link to="#">Terms & Policy</Link> of the application.
                    </p>
                  </div>

                  <div className="mt-3">
                    <button type="submit" variant="primary" className="submit">
                      Sign Up
                    </button>
                  </div>
                  <div className="text-center mt-5 login-bottom-line">
                    <p>
                      Have an account?<Link to="/">Login</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
