import { createSlice } from "@reduxjs/toolkit";
import { dashboardCardAction, getShopList, shopDetailParticularById } from "../Actions/shopAction";


const initialState = {
  loading: false,
  shopListDetail: [],
  error: false,
  shopDetailById: [],
  shopDashboardCard: {}
};

const shopAuthSlice = createSlice({
  name: "shopAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShopList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShopList.fulfilled, (state, { payload }) => {
        state.shopListDetail = payload.data
        state.loading = false;
      })
      .addCase(getShopList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // shop detail by id 
      .addCase(shopDetailParticularById.pending, (state) => {
        state.loading = true;
      })
      .addCase(shopDetailParticularById.fulfilled, (state, { payload }) => {
        state.shopDetailById = payload.data
        state.loading = false;
      })
      .addCase(shopDetailParticularById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      // shop dashboard card
      .addCase(dashboardCardAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(dashboardCardAction.fulfilled, (state, { payload }) => {
        state.shopDashboardCard = payload.data
        state.loading = false;
      })
      .addCase(dashboardCardAction.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
  },
});


export default shopAuthSlice.reducer;
