import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Icons from "../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { dashboardCardAction } from "../Redux/Actions/shopAction";
import NotificationComp from "../Components/common/NotificationComp";
import CustomerReviewsComponent from "../Components/common/CustomerReviewsComponent";
import DashboardCustomerReviews from "../Components/common/DashboardCustomerReviews";
import GrowthChart from "../Components/common/GrowthChart";
import WeeklyOrdersCharts from "../Components/common/WeeklyOrdersCharts";
import RevenueComparisonChart from "../Components/RevenueComparisonChart";
import WeeklyCustomerChart from "../Components/common/WeeklyCustomerChart";

export default function Dashboard() {
  const dispatch = useDispatch();
  const location = useLocation();


  const { shopDashboardCard } = useSelector((state) => state?.shopAuthData);

  useEffect(() => {
    dispatch(dashboardCardAction());
  }, []);


  const getQueryParams = (queryString) => {
    const params = new URLSearchParams(queryString);
    return params.get('token');
  };

  useEffect(() => {
    const extractedToken = getQueryParams(location.search);
    if (extractedToken) {
      localStorage.setItem("token", extractedToken);
      localStorage.setItem("isLoggedIn", "true");
      window.location.href = "/Dashboard";
    }
  }, [location.search]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <div className="common-head">
            <h2>{Icons.dashboardIcon}Dashboard</h2>
          </div>
          <div className="common-icon-side">
            <NotificationComp />
          </div>
        </div>
      </div>
      <Container fluid className="p-0">
        <div className="dashboard-items pb-0">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <Row>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalOrder}</div>
                    <div className="text-part">
                      <h2>
                        {shopDashboardCard?.confirmed_orders +
                          shopDashboardCard?.pending_orders}
                      </h2>
                      <p>Total Orders</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalDeliveredIcon}</div>
                    <div className="text-part">
                      <h2>{shopDashboardCard?.delivered_orders}</h2>
                      <p>Total Delivered</p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalCanceledIcon}</div>
                    <div className="text-part">
                      <h2>{shopDashboardCard?.cancelled_orders} </h2>
                      <p>Total Canceled </p>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="icon-part">{Icons.TotalRevenueIcon}</div>
                    <div className="text-part">
                      <h2>${0} </h2>
                      <p>Total Revenue</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xxl={6} xl={6} lg={6} className="mt-4">
            <div className="dash-inner-boxes-pie">
              <div className="dummy-graph">
                {/* <img src={require("../Assets/Images/card_chart_order.png")} /> */}
                {/* <img src={require("../Assets/Images/pie_chart.png")} />
                 */}
               <h2>Pie</h2>
                <div style={{ display: "flex", justifyContent: "space-between" ,ga: "10px" }}>
                  <GrowthChart />
                  <GrowthChart />
                  <GrowthChart />
                </div>

              </div>
            </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} className="mt-4">
            <div className="dash-inner-boxes-pie">
              <div className="dummy-graph">
                {/* <img src={require("../Assets/Images/card_chart_order.png")} /> */}
                <WeeklyOrdersCharts/>
                {/* <img src={require("../Assets/Images/pie_chart.png")} /> */}
              </div>
              </div>
            </Col>
          </Row>

          <Row className="dash-bottom mt-4">
            <Col xxl={7} xl={7} lg={7}>
            <div className="dash-inner-boxes-pie">

              <div className="dummy-graph">
                {/* <img src={require("../Assets/Images/card_total_revenue.png")} /> */}
                <RevenueComparisonChart/>
              </div>
            </div>
            </Col>
            <Col xxl={5} xl={5} lg={5}>
            <div className="dash-inner-boxes-pie">
            <div className="dummy-graph">
                {/* <img src={require("../Assets/Images/card_customer_map.png")} /> */}
                <WeeklyCustomerChart/>
              </div>
            </div>
           
            </Col>

            {/* <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Users</h6>
                <Users />
              </div>
            </Col> */}
            {/* <Col xxl={4} xl={4} lg={4}>
                  <div className="dashbox-side">
                    <h2>MEMBERS </h2>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL MEMBERS </h6>
                      <h4>10,292</h4>
                      <p>10% increase from last month</p>
                    </div>
                    <div className="dash-inner-boxes">
                      <h6>TOTAL CLUBS </h6>
                      <h4>761</h4>
                      <p>10% increase from last month</p>
                    </div>
                  </div>
                </Col> */}
            {/* <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Total Orders</h6>
                <Orders />
              </div>
            </Col> */}
          </Row>
        </div>

        {/* Customer Reviews */}
        <DashboardCustomerReviews />

      </Container>
    </Layout>
  );
}
